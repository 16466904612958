import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { ICMSRepository } from '@common/interfaces';
import { cmsClientInstance } from '@common/clients';

class CMSRepository extends AxiosRepository implements ICMSRepository {
  public constructor(protected authClient: AxiosInstance) {
    super(cmsClientInstance, authClient);
  }

  async getElement(slug: string) {
    const { data } = await this.get('/pages/find/', { params: { html_path: slug } });
    return data;
  }
}

export default CMSRepository;
