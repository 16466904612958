import { AxiosInstance, AxiosRequestConfig } from 'axios';
import AxiosRepository from './axios.repository';
import { IMonolithAuthorizedRepository } from '@common/interfaces';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authenticateRequest = function (req: AxiosRequestConfig<any>) {
  const authorization = String(req.headers?.['Authorization']);
  const isWithoutBearer = ['/user-profile/managed-facilities/'].some((path) => req.url?.includes(path));

  if (isWithoutBearer && authorization.startsWith('Bearer')) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    req.headers!['Authentication'] = authorization.split(' ')[1];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    delete req.headers!['Authorization'];
  }

  return req;
};

class MonolithAuthorizedRepository extends AxiosRepository implements IMonolithAuthorizedRepository {
  constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
    this.axiosClient.interceptors.request.use(authenticateRequest);
  }

  async getManagedFacilities() {
    const { data } = await this.get(`${process.env.REACT_APP_HBH_API}/v2/user-profile/managed-facilities/`, {
      params: {
        category: 'workshop',
        page_size: 10000,
      },
    });
    return data;
  }
}

export default MonolithAuthorizedRepository;
