import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CMSNavigation } from '@common/interfaces';
import css from './styles.module.scss';

interface INavigation {
  navigation?: CMSNavigation[];
}

const Navigation: FC<INavigation> = ({ navigation = [] }) => {
  const { pathname } = useLocation();
  return (
    <nav className={css.navigation}>
      {navigation.map(({ icon, link_url, link_title, open_in_new_tab }, idx) => {
        const target = open_in_new_tab ? '_blank' : '_self';
        const isActive = pathname === link_url;
        return (
          <Link to={link_url} key={idx} className={`${css.link} ${isActive ? css.active : ''}`} target={target}>
            {icon && <img src={icon} alt='' />}
            {link_title}
          </Link>
        );
      })}
    </nav>
  );
};

export default Navigation;
