import { Nullable } from '../common';

export enum StreamFieldTypes {
  button_group = 'button_group',
  article = 'article',
  card = 'card',
  section = 'section',
  label = 'label',
}

export interface IStreamFieldObject {
  type: StreamFieldTypes;
  id: string;
  value: CMSButtonGroup | CMSArticle | CMSSection | CMSCard;
}

export interface CMSPagesTypes {
  type: 'HomePage';
}

export interface CMSImage {
  id: number;
  meta: {
    type: string;
    detail_url: string;
    download_url: string;
  };
  title: string;
}

export interface CMSPageResponse {
  id: number;
  page_type: CMSPagesTypes['type'];
  content: IStreamFieldObject[];
  background: Nullable<CMSImage>;
  title: string;
  meta?: {
    detail_url: string;
    slug: string;
    type: string;
  };
}

export interface CMSButtonGroup {
  label: string;
  color: string;
  background: string | null;
  buttons: CMSButton[];
}

export interface CMSButton {
  label: string;
  color: string;
  path: string;
  icon: Nullable<{
    urL: string;
    full_url: string;
    width: string;
    height: string;
    alt: string;
  }>;
}

export interface CMSArticle {
  body: string;
  label: string;
}

export interface CMSCard {
  background: Nullable<{
    full_url: string;
  }>;
  color: string;
  label: string;
  body: string;
  additional_link: Nullable<{
    label: string;
    url: string;
    is_external: boolean;
  }>;
}

export interface CMSSection {
  label?: string;
  value: IStreamFieldObject[];
}

export interface CMSNavigation {
  icon: string;
  link_url: string;
  link_title: string;
  is_external: boolean;
  open_in_new_tab: boolean;
}

export interface CMSNavigationResponse {
  header: string;
  title: string;
  subheader: string;
  id: number;
  navigation: CMSNavigation[];
  home_page_slug: string;
  logo: Nullable<CMSImage>;
}
