import { parse, addMinutes, startOfHour, format } from 'date-fns';

const roundTimeToNearestHalfHour = (timeString: string): string => {
  const [, minutes] = timeString.split(':').map(Number);

  const time = parse(timeString, 'HH:mm:ss', new Date());
  const roundedMinutes = Math.round(minutes / 30) * 30;
  const roundedTime = addMinutes(startOfHour(time), roundedMinutes);

  return format(roundedTime, 'HH:mm:ss');
};

export default roundTimeToNearestHalfHour;
