import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { IUserRepository } from '@common/interfaces';

class UserRepository extends AxiosRepository implements IUserRepository {
  constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  async getCurrentUser() {
    const { data } = await this.get(`/accounts/me/`);
    return data;
  }
}

export default UserRepository;
