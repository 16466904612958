import React, { Dispatch, FC, SetStateAction } from 'react';
import { useFormState, useMultistepApi } from 'informed';
import { FormTempValues, IYupSchema, yupSchemas } from '@pages/BookingFormPage/consts';
import NavigationStepper from '../NavigationStepper';
import { UserIcon, ProjectIcon, ReviewIcon } from '@assets/svg';

interface SideMenuProps {
  setYupSchema: Dispatch<SetStateAction<IYupSchema>>;
}

const SideMenu: FC<SideMenuProps> = ({ setYupSchema }) => {
  const { setCurrent, getCurrentStep } = useMultistepApi();

  const formState = useFormState();

  const currentStep = getCurrentStep();

  const { details, asset, review } = formState.values as unknown as FormTempValues;

  const onClick = (step: string) => {
    setCurrent(step);
    setYupSchema(yupSchemas[step]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const stepsConfig = [
    {
      label: 'Your details',
      icon: <UserIcon />,
      isCompleted: Boolean(
        details &&
          details.manager_name &&
          details.manager_surname &&
          details.manager_email &&
          details.requesting_agency &&
          details.requestor_name &&
          details.requestor_surname &&
          details.requestor_phone_number &&
          details.requestor_email
      ),
      isDisabled: false,
      onClick: () => onClick('details'),
      key: 'details',
    },
    {
      label: 'Asset information',
      icon: <ProjectIcon />,
      isCompleted: Boolean(
        asset &&
          asset.current_odometer_value &&
          asset.has_spare_parts &&
          asset.known_issues &&
          asset.make &&
          asset.model &&
          asset.odometer_metric &&
          asset.plate_number &&
          asset.type_of_asset
      ),
      isDisabled: !asset && currentStep !== 'asset',
      onClick: () => onClick('asset'),
      key: 'asset',
    },
    {
      label: 'Review and submit',
      icon: <ReviewIcon />,
      isCompleted: Boolean(review?.remarks),
      isDisabled: !details || !asset,
      onClick: () => onClick('review'),
      key: 'review',
    },
  ];

  const currentStepIdx = stepsConfig.findIndex((step) => step.key === currentStep);

  return <NavigationStepper disableIfBeforeCurrentStep={false} config={stepsConfig} currentStep={currentStepIdx} />;
};

export default SideMenu;
