import React, { FC } from 'react';
import { ValueItemColorized } from '@common/interfaces';
import { formatToMoneyString, stringToColour } from '@common/utils';
import { Empty } from '@components';
import css from './styles.module.scss';
import { DEFAULT_CHART_COLORS } from '@common/constants';

interface ILineChart {
  data: ValueItemColorized[];
  formatting?: 'default' | 'money';
  title: string;
}

const LineChart: FC<ILineChart> = ({ data, formatting = 'default', title }) => {
  const total = data.reduce((prev, { value }) => {
    return prev + (value || 0);
  }, 0);

  if (total === 0) return <Empty />;

  const colorizedData = data.map(({ backgroundColor, label, ...rest }, idx) => {
    return {
      ...rest,
      label,
      backgroundColor: backgroundColor || DEFAULT_CHART_COLORS[idx] || stringToColour(label),
    };
  });

  return (
    <div className={css.wrapper}>
      <div className={css.title}>{title}</div>
      <div className={css.line}>
        {colorizedData.map(({ value, backgroundColor }, index) => {
          if (!value) return null;
          const part = ((value / total) * 100).toFixed(2);
          const valueString = formatting === 'money' ? formatToMoneyString(value) : value;

          return (
            <div className={css.block} style={{ width: `${part}%`, backgroundColor }} key={index}>
              <div className={css.value}>{`${valueString} (${part}%)`}</div>
            </div>
          );
        })}
      </div>
      <div className={css.labels}>
        {colorizedData.map(({ label, value, backgroundColor }, index) => {
          if (value === 0) return null;

          return (
            <div className={css.label} key={index}>
              <div className={css.dot} style={{ backgroundColor }}></div>
              <div className={css.text}>{label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LineChart;
