import { parse } from 'date-fns';

// Note: convert 2024-04-10T20:00:00Z to Wed Apr 10 2024 20:00:00 GMT+0300

const getLocalDate = (isoDate: string) => {
  const [date, time] = isoDate.slice(0, -1).split('T');
  return parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm:ss', new Date());
};

export default getLocalDate;
