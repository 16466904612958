import { Asset, IDropdownOption, MakeOption, ModelOption } from '@common/interfaces';

export interface IAssetDropdownOption extends IDropdownOption {
  make?: MakeOption;
  model?: ModelOption;
  year?: string;
  odometer_value?: string;
  odometer_metric?: string;
  __isNew__?: boolean;
}

const mapPlateNumbers = (assets?: Asset[]): IAssetDropdownOption[] => {
  if (!assets) return [];
  return assets.map(({ plate_number, make, model, year, odometer_value, odometer_type }) => ({
    label: plate_number,
    value: plate_number,
    make,
    model,
    odometer_metric: odometer_type,
    odometer_value: new Intl.NumberFormat('en-US').format(odometer_value),
    year: year ? year.split('-')[0] : '',
  }));
};

export default mapPlateNumbers;
