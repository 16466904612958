import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { IYupSchema, FormTempValues, yupSchemas, STEPS, MAX_REMARKS_LENGTH, ReviewStepValues } from '../../consts';
import { useFormState, useMultistepApi, Multistep, useFieldApi } from 'informed';
import Navigation from '../Navigation';
import Subtitle from '../Subtitle';
import { ReviewIcon, EditIcon } from '@assets/svg';
import InformedField from '../InformedField';
import Counter from '../Counter';
import { Checkbox, TextAreaField } from '@components';
import css from './styles.module.scss';

interface IReviewRow {
  label: string;
  value?: string | number | Array<string>;
}

interface ReviewStepProps {
  setYupSchema: Dispatch<SetStateAction<IYupSchema>>;
}

const ReviewRow: FC<IReviewRow> = ({ label, value }) => {
  return (
    <div className={css.row}>
      <div className={css.label}>{label}</div>
      {Array.isArray(value) ? (
        <ol className={css.list}>
          {value.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ol>
      ) : (
        <div className={css.value}>{value || '-'}</div>
      )}
    </div>
  );
};

const ReviewStep: FC<ReviewStepProps> = ({ setYupSchema }) => {
  const formState = useFormState();
  const { setCurrent } = useMultistepApi();
  const [confirmEnabled, setConfirmEnabled] = useState(false);
  const { getValue } = useFieldApi(STEPS.REVIEW);

  const stepValues = (getValue() || {}) as Partial<ReviewStepValues>;

  const { remarks = '' } = stepValues;

  const { details, asset } = formState.values as unknown as FormTempValues;

  const onClick = (step: string) => {
    setCurrent(step);
    setYupSchema(yupSchemas[step]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Multistep.Step step={STEPS.REVIEW}>
      <Subtitle text='Confirm and book the maintenance' icon={<ReviewIcon />} />

      <div className={css.section}>
        <div className={css.title}>
          Your details{' '}
          <button className={css.titleButton} onClick={() => onClick(STEPS.DETAILS)}>
            Edit <EditIcon />
          </button>
        </div>
        {details ? (
          <>
            <ReviewRow label='Name' value={`${details.requestor_name} ${details.requestor_surname}`} />
            <ReviewRow label='Email' value={details.requestor_email} />
            <ReviewRow label='Phone number' value={details.requestor_phone_number} />
            <ReviewRow label='Authorizing manager' value={`${details.manager_name} ${details.manager_surname}`} />
            <ReviewRow label='Authorizing manager email' value={details.manager_email} />
            <ReviewRow
              label='Person authorized for delivery and collect'
              value={
                details.collector_name && details.collector_surname
                  ? `${details.collector_name} ${details.collector_surname}`
                  : undefined
              }
            />
            <ReviewRow label='Person authorized for delivery and collect email' value={details.collector_email} />
            <ReviewRow
              label='Person authorized for delivery and collect phone number'
              value={details.collector_phone_number}
            />
          </>
        ) : null}
      </div>

      <div className={css.section}>
        <div className={css.title}>
          Asset information{' '}
          <button className={css.titleButton} onClick={() => onClick(STEPS.ASSET)}>
            Edit <EditIcon />
          </button>
        </div>
        {asset ? (
          <>
            <ReviewRow label='Asset type' value={asset.type_of_asset?.label} />
            <ReviewRow label='Make' value={asset.make?.label} />
            <ReviewRow label='Model' value={asset.model?.label} />
            <ReviewRow label='Year of the asset' value={asset.year_of_asset} />
            <ReviewRow label='Plate, Boat or Generator number' value={asset.plate_number?.label} />
            <ReviewRow label='Job reason' value={asset.job_reason?.label} />
            <ReviewRow
              label='Current odometer value'
              value={`${asset.current_odometer_value} ${asset.odometer_metric?.label}`}
            />
            <ReviewRow
              label='Are you bringing the needed spare parts to perform the service?'
              value={asset.has_spare_parts?.label}
            />
            <ReviewRow
              label='Is a quotation required before the job start?'
              value={asset.is_quotation_required?.label}
            />
            <ReviewRow
              label='Known fault to be checked'
              value={asset.known_issues?.map(({ text, type_of_repair }) => `${text} / ${type_of_repair}`)}
            />
          </>
        ) : null}
      </div>

      <InformedField label='Additional remarks (optional)' className={css.remarksField}>
        <TextAreaField name='remarks' />
        <Counter length={remarks.length} max={MAX_REMARKS_LENGTH} />
      </InformedField>

      <div className={css.terms}>
        <Checkbox
          label='I have read and accept the Terms and Conditions *'
          checked={confirmEnabled}
          onChange={(checked) => setConfirmEnabled(checked)}
        />
        <a className={css.link} href='https://unbooking.org/en/terms-of-use/' target='_blank' rel='noreferrer'>
          Click here to open in a new window
        </a>
      </div>

      <Navigation setYupSchema={setYupSchema} confirmEnabled={confirmEnabled} />
    </Multistep.Step>
  );
};

export default ReviewStep;
