import React, { ChangeEvent, FC, useState } from 'react';
import { ChevronIcon } from '@assets/icons';
import css from './styles.module.scss';

interface ITablePagination {
  className?: string;
  onChangePage: (page: number) => void;
  onPageSizeChange: (page: number) => void;
  page: number;
  pageSize: number;
  showPageSize?: boolean;
  totalCount?: number;
}

const defaultPageSizes = [10, 30, 50, 100, 200];

const TablePagination: FC<ITablePagination> = ({
  className,
  onChangePage,
  onPageSizeChange,
  page: defaultPage,
  pageSize: specPageSize,
  showPageSize = true,
  totalCount,
}) => {
  if (!totalCount || totalCount < defaultPageSizes[0]) return null;

  const pageSizes =
    specPageSize && !defaultPageSizes.includes(specPageSize)
      ? [...defaultPageSizes, specPageSize].sort((a, b) => a - b)
      : defaultPageSizes;

  const defaultPageSize = specPageSize || pageSizes[0];

  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const pageCount = Math.ceil(totalCount / pageSize);
  const pages = [];
  for (let i = 1; i <= pageCount; i++) {
    pages.push(i);
  }

  const handlePageSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    setPageSize(value);
    onPageSizeChange(value);
    setPage(1);
    onChangePage(1);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    onChangePage(page);
  };

  const itemsText = `${(page - 1) * pageSize + 1} - ${
    page === pageCount ? totalCount : page * pageSize
  } of ${totalCount}`;

  const pagesText = `of ${pageCount} ${pageCount === 1 ? 'page' : 'pages'}`;

  return (
    <div className={`${css.pagination} ${className}`}>
      {showPageSize && (
        <>
          <div className={css.items}>
            <span>Items per page:</span>
            <select className={css.select} value={pageSize} onChange={handlePageSizeChange}>
              {pageSizes.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <span>{itemsText}</span>
        </>
      )}
      <div className={css.pages}>
        <select className={css.select} value={page} onChange={(e) => handlePageChange(Number(e.target.value))}>
          {pages.map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </select>
        <span>{pagesText}</span>
      </div>
      <button disabled={page === 1} onClick={() => handlePageChange(page - 1)} title='Previous'>
        <ChevronIcon type='left' />
      </button>
      <button disabled={page === pageCount} onClick={() => handlePageChange(page + 1)} title='Next'>
        <ChevronIcon type='right' />
      </button>
    </div>
  );
};

export default TablePagination;
