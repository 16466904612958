import React, { Dispatch, FC, SetStateAction } from 'react';
import { Form, FormState, useFieldApi, useFormState } from 'informed';
import { Button, DateField, DefaultField, Dropdown, InformedField } from '@components';
import { SuccessIcon } from '@assets/svg';
import { useFacilityContext, useRepository } from '@context';
import { useMutation } from 'react-query';
import {
  IAppointmentType,
  IAppointmentURLType,
  IBookingDetails,
  IDraftAppointment,
  IDropdownOption,
  IMechanic,
} from '@common/interfaces';
import * as Yup from 'yup';
import {
  addMinutes,
  areIntervalsOverlapping,
  format,
  getDay,
  isAfter,
  isBefore,
  isSameDay,
  parse,
  startOfWeek,
  subMinutes,
} from 'date-fns';
import {
  LINKS,
  DEFAULT_SERVER_DATE_FORMAT,
  DEFAULT_SERVER_TIME_FORMAT,
  DEFAULT_TIME_INTERVAL,
  DEFAULT_START_TIME,
  DEFAULT_END_TIME,
} from '@common/constants';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { GroupBase, OptionsOrGroups } from 'react-select';
import {
  combineDateAndTime,
  getDisabledDays,
  getLocalDate,
  getLocalTime,
  isSameOrAfter,
  isSameOrBefore,
  roundTimeToNearestHalfHour,
} from '@common/utils';
import css from './styles.module.scss';
import { Spin } from 'antd';
import { IEvent } from '@components/EventBlock';

interface AppointmentForm {
  date?: Date;
  start_time?: Date;
  end_time?: Date;
  mechanic?: IDropdownOption;
}

interface IAppointmentForm {
  bookingDetails: IBookingDetails;
  setStartPlannerDate: Dispatch<SetStateAction<Date>>;
  events: IEvent[];
}

const mapMechanics = (mechanics?: IMechanic[]) => {
  if (!mechanics) return [];
  return mechanics
    .filter((mechanic) => mechanic.is_active)
    .map(({ first_name, last_name, uuid }) => ({
      label: `${first_name} ${last_name}`,
      value: uuid,
    }));
};

const AppointmentForm: FC<IAppointmentForm> = ({ events, bookingDetails, setStartPlannerDate }) => {
  const { facilityId, facility } = useFacilityContext();
  const navigate = useNavigate();
  const { appointmentRepository } = useRepository();
  const { type } = useParams();

  const {
    request_number,
    requesting_agency,
    make,
    model,
    plate_number,
    known_issues,
    uuid: bookingId,
    jobcard,
    appointments,
  } = bookingDetails;

  const isCheckOut = type === IAppointmentURLType.CHECK_OUT;
  const maxDate = !isCheckOut && jobcard?.job_date ? getLocalDate(jobcard?.job_date) : undefined;
  const minDate = isCheckOut && jobcard?.job_end_date ? getLocalDate(jobcard?.job_end_date) : undefined;
  const disabledDays = getDisabledDays(facility.work_time);

  const filteredDate = (date: Date) => {
    const day = getDay(date);
    return !disabledDays.includes(day);
  };

  const startTime = facility.work_time
    ? parse(roundTimeToNearestHalfHour(facility.work_time.start_time), 'HH:mm:ss', new Date())
    : parse(DEFAULT_START_TIME, 'HH:mm:ss', new Date());
  const endTime = facility.work_time
    ? parse(roundTimeToNearestHalfHour(facility.work_time.end_time), 'HH:mm:ss', new Date())
    : parse(DEFAULT_END_TIME, 'HH:mm:ss', new Date());

  const YupStartTimeType = () =>
    Yup.date()
      .nullable()
      .test(
        'start_time',
        ({ value }) => {
          if (!value) return 'Required';
          return 'Should be correct start time';
        },
        function (date) {
          if (!date) return false;
          const fullDate = combineDateAndTime(this.parent.date, date);
          return (
            isSameOrAfter(date, startTime) &&
            isBefore(date, endTime) &&
            (!this.parent.end_time || isBefore(date, this.parent.end_time)) &&
            (!maxDate || isSameOrBefore(fullDate, maxDate)) &&
            (!minDate || isSameOrAfter(fullDate, minDate))
          );
        }
      );

  const YupEndTimeType = () =>
    Yup.date()
      .nullable()
      .test(
        'end_time',
        ({ value }) => {
          if (!value) return 'Required';
          return 'Should be correct end time';
        },
        function (date) {
          if (!date) return false;
          const fullDate = combineDateAndTime(this.parent.date, date);
          return (
            isAfter(date, startTime) &&
            isSameOrBefore(date, endTime) &&
            (!this.parent.start_time || isAfter(date, this.parent.start_time)) &&
            (!maxDate || isSameOrBefore(fullDate, maxDate)) &&
            (!minDate || isSameOrAfter(fullDate, minDate))
          );
        }
      );

  const yupSchema = Yup.object().shape({
    date: Yup.date().required('Required'),
    start_time: YupStartTimeType(),
    end_time: YupEndTimeType(),
    mechanic: Yup.object(),
  });

  const currentAppointment = isCheckOut
    ? appointments.find((a) => a.appointment_type === IAppointmentType.CHECK_OUT)
    : appointments.find((a) => a.appointment_type === IAppointmentType.CHECK_IN);

  const loadMechanicOptions = async (search: string, loadedOptions: OptionsOrGroups<unknown, GroupBase<unknown>>) => {
    const { results, next } = await appointmentRepository.getMechanics(facilityId, {
      limit: 10,
      search,
      offset: loadedOptions.length,
    });

    return {
      options: mapMechanics(results),
      hasMore: Boolean(next),
    };
  };

  const { mutate: createAppointment, isLoading: inCreationProgress } = useMutation(
    (appointment: IDraftAppointment) => appointmentRepository.createAppointment(facilityId, appointment),
    {
      onSuccess: () => {
        toast.success('Appointment saved, email sent to customer');
        setTimeout(() => navigate(LINKS.booking(bookingId)), 1000);
      },
    }
  );

  const { mutate: updateAppointment, isLoading: inUpdatingInProgress } = useMutation(
    (appointment: Partial<IDraftAppointment>) =>
      appointmentRepository.updateAppointment(facilityId, currentAppointment?.uuid || '', appointment),
    {
      onSuccess: () => {
        toast.success('Appointment was updated');
        setTimeout(() => navigate(LINKS.booking(bookingId)), 1000);
      },
    }
  );

  const onFormSubmit = ({ values }: FormState) => {
    const { date, end_time, mechanic, start_time } = values as unknown as AppointmentForm;

    const formattedDate = date ? format(date, DEFAULT_SERVER_DATE_FORMAT) : '';
    const appointment = {
      from_to_datetime_range: {
        lower: start_time ? `${formattedDate} ${format(start_time, DEFAULT_SERVER_TIME_FORMAT)}` : '',
        upper: end_time ? `${formattedDate} ${format(end_time, DEFAULT_SERVER_TIME_FORMAT)}` : '',
      },
      assign_to: mechanic?.value,
      booking: bookingId,
      appointment_type: isCheckOut ? IAppointmentType.CHECK_OUT : IAppointmentType.CHECK_IN,
    };

    // Note: validate mechanic interceptions
    if (appointment.assign_to) {
      const mechanicAppointments = events.filter(
        ({ assign_to, uuid }) => assign_to === appointment.assign_to && currentAppointment?.uuid !== uuid
      );
      const intersection = mechanicAppointments.find(({ start, end }) => {
        return areIntervalsOverlapping(
          { start: getLocalDate(start), end: getLocalDate(end) },
          {
            start: new Date(appointment.from_to_datetime_range.lower),
            end: new Date(appointment.from_to_datetime_range.upper),
          },
          { inclusive: false }
        );
      });

      if (intersection) {
        toast.error('The time slot is occupied for the selected mechanic. Choose other mechanic or other time.');
        return;
      }
    }

    if (currentAppointment) {
      updateAppointment(appointment);
    } else {
      createAppointment(appointment);
    }
  };

  const DateStartTimeField = () => {
    const { getValue: getEndTimeValue, setValue: setEndTimeValue } = useFieldApi('end_time');
    const { values } = useFormState();

    const maxTime =
      maxDate && isSameDay(maxDate, values.date as Date)
        ? subMinutes(maxDate, DEFAULT_TIME_INTERVAL)
        : subMinutes(endTime, DEFAULT_TIME_INTERVAL);

    const minTime = minDate && isSameDay(minDate, values.date as Date) ? minDate : startTime;

    return (
      <DateField
        minTime={minTime}
        maxTime={maxTime}
        type='time'
        name='start_time'
        onChange={(date) => {
          const endTime = getEndTimeValue() as Date;
          if (date && (!endTime || isSameOrAfter(date, endTime))) {
            setEndTimeValue(addMinutes(date, DEFAULT_TIME_INTERVAL));
          }
        }}
      />
    );
  };

  const DateEndTimeField = () => {
    const { values } = useFormState();

    const startTimeValue = (values.start_time as Date) || startTime;

    const maxTime = maxDate && isSameDay(maxDate, values.date as Date) ? maxDate : endTime;

    const minTime =
      minDate && isSameDay(minDate, values.date as Date) ? minDate : addMinutes(startTimeValue, DEFAULT_TIME_INTERVAL);

    return <DateField minTime={minTime} maxTime={maxTime} type='time' name='end_time' />;
  };

  const initialValues = currentAppointment
    ? {
        date: getLocalDate(currentAppointment.from_to_datetime_range.from),
        start_time: getLocalTime(currentAppointment.from_to_datetime_range.from),
        end_time: getLocalTime(currentAppointment.from_to_datetime_range.to),
        mechanic:
          !isCheckOut && currentAppointment.assign_to
            ? {
                label: `${currentAppointment.assign_to.first_name} ${currentAppointment.assign_to.last_name}`,
                value: currentAppointment.assign_to.uuid,
              }
            : undefined,
      }
    : undefined;

  const loading = inCreationProgress || inUpdatingInProgress;

  const subtitle = isCheckOut ? 'Schedule check-out' : 'Schedule check-in / inspection';

  return (
    <Form initialValues={initialValues} className={css.form} onSubmit={onFormSubmit} yupSchema={yupSchema}>
      <Spin spinning={loading}>
        <div className={css.subtitle}>{subtitle}</div>
        <div className={css.fields}>
          <InformedField label='Booking *' className={css.field}>
            <DefaultField
              text={
                <>
                  {request_number}
                  <span>
                    {' '}
                    - {requesting_agency} - {make?.label} {plate_number}
                  </span>
                </>
              }
            />
          </InformedField>
          {isCheckOut ? (
            <InformedField label='Related jobcard *' className={css.field}>
              <DefaultField text={jobcard?.job_number} />
            </InformedField>
          ) : (
            <InformedField label='Mechanic' className={css.field}>
              <Dropdown
                loadOptions={loadMechanicOptions}
                name='mechanic'
                isSearchable
                placeholder='Select a mechanic'
              />
            </InformedField>
          )}
          <InformedField label='Date *' className={css.field}>
            <DateField
              filterDate={filteredDate}
              type='date'
              minDate={minDate || new Date()}
              maxDate={maxDate}
              name='date'
              onChange={(date) => {
                if (date) setStartPlannerDate(startOfWeek(new Date(date), { weekStartsOn: 1 }));
              }}
            />
          </InformedField>
          <div className={css.row}>
            <InformedField label='Start time *' className={css.field}>
              <DateStartTimeField />
            </InformedField>
            <InformedField label='End time *' className={css.field}>
              <DateEndTimeField />
            </InformedField>
          </div>
          <hr className={css.divider} />
          <div className={css.infoLabel}>Asset type</div>
          <div className={css.infoValue}>
            {plate_number}, {make?.label} {model?.label}
          </div>
          <div className={css.infoLabel}>Known faults to be checked</div>
          <div className={css.infoValue}>
            <ol>
              {known_issues.map(({ type_of_repair, text }, idx) => (
                <li key={idx}>
                  {type_of_repair}: {text}
                </li>
              ))}
            </ol>
          </div>
        </div>
        <Button
          className={css.button}
          text='Confirm and notify customer'
          variant='forest'
          iconR={<SuccessIcon />}
          type='submit'
        />
      </Spin>
    </Form>
  );
};

export default AppointmentForm;
