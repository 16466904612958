import { IJobcard, Country, ValueItem } from '@common/interfaces';
import { DataItem, DefaultListParams, IAppointment, IDropdownOption, IFocalPoint, Nullable } from '@common/interfaces';

export enum IBookingStatus {
  // To process
  NOT_PROCESSED = 'not_processed', // auto - when booking created
  ACCEPTED = 'accepted', // auto - after email sent to client
  CHECK_IN_CONFIRMED_BY_CLIENT = 'check_in_confirmed_by_client', // manual - after client confirmed
  ON_HOLD = 'on_hold', // manual - selected when status is "To be processed".

  // In progress
  INSPECTED = 'inspected', // manual - after inspection done
  PFI_IN_APPROVAL = 'pfi_in_approval', // manual – when they circulate the PFI to collect internal approval.
  PFI_SENT = 'pfi_sent', // manual – when they send PFI by email to client.
  SIGNED_PFI_RECEIVED = 'signed_pfi_received', // automatic - when user clicks on Confirm PFI approval and Upload signed PFI
  WORK_IN_PROGRESS = 'in_progress', // manual

  // Closed
  REJECTED = 'rejected', // auto - after rejection of booking
  WORK_COMPLETED = 'work_completed', // auto - when Jobcard is closed on FMS
  READY_FOR_PICK_UP = 'ready_for_pick_up', // auto - after check-out scheduled
  HANDOVER_DONE = 'handover_done', // manual
  INVOICE_SENT = 'invoice_sent', // manual
  INVOICE_PAID = 'invoice_paid', // manual
  COMPLETED = 'completed', // manual
  CANCELLED = 'cancelled_by_fp', // manual
}

// Note: can be extended in admin panel
export enum ITypeOfAsset {
  ARMOURED = 'Armoured vehicle',
  GENERATOR = 'Generator',
  LIGHT_VEHICLE = 'Light Vehicle',
  SHERPS = 'Sherps',
  TRUCK = 'Truck',
}

export enum IBookingListTab {
  TO_PROCESS = 'TO_PROCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}

export interface KnownIssue {
  type_of_repair: string;
  text: string;
}

export interface MakeOption {
  label: string;
  value: string;
}

export interface ModelOption {
  label: string;
  value: string;
  make: string;
}

export interface Asset {
  asset_type: ITypeOfAsset;
  chassis_number: string;
  cost_centre: string;
  country: Country;
  department_wfp: string;
  latest_sync_time: string;
  make: MakeOption;
  model: ModelOption;
  number: string;
  odometer_type: string;
  odometer_value: number;
  plate_number: string;
  record_number: number;
  status: string;
  uuid: string;
  year: string;
}

export interface HomeStatData {
  to_be_processed: number;
  to_invoice: number;
}

export interface BookingAnalytics {
  active_jobcards: Nullable<number>;
  avg_days_at_workshop: { label: string; data: ValueItem[] }[];
  avg_request_processing_days_by_status: ValueItem[];
  requests_by_asset_type: ValueItem[];
  requests_by_partner_type: ValueItem[];
  requests_by_reason_type: ValueItem[];
  total: Nullable<number>;
  total_labour_cost: Nullable<number>;
  total_mcr_cost: Nullable<number>;
  total_parts_cost: Nullable<number>;
}

export interface BookingAnalyticsParams {
  created_after?: string;
  created_before?: string;
  facilities: string;
  type_of_asset?: string;
}

export interface BookingAnalyticsOptions {
  type_of_asset: DataItem[];
}

export interface BookingAnalyticsOptionsParams {
  created_after?: string;
  created_before?: string;
  facilities: string;
}

export interface ModelsListParams extends DefaultListParams {
  make?: string;
}

export interface IBookingListParams extends DefaultListParams {
  created_after?: string;
  created_before?: string;
  requesting_agency?: string;
  show?: string;
  state?: string;
  tab_type?: string;
  type_of_asset?: string;
}

export interface IBookingFiltersParams {
  tab_type: IBookingListTab;
}

export interface IBookingFilters {
  states: DataItem[];
  agencies: DataItem[];
  type_of_asset: DataItem[];
}

export interface IBooking {
  allowed_transitions: DataItem[];
  current_odometer_value: number;
  facility_id_on_hbh: number;
  has_spare_parts: boolean;
  is_quotation_required: boolean;
  make: Nullable<string>;
  model: Nullable<string>;
  odometer_metric: string;
  plate_number: string;
  reference_code: string;
  remarks: string;
  request_number: string;
  requested: string;
  requesting_agency: string;
  requestor_name: string;
  requestor_surname: string;
  state: IBookingStatus;
  type_of_asset: string;
  uuid: string;
}

// Booking Form
interface AssetDetails {
  current_odometer_value: number;
  has_spare_parts: boolean;
  is_quotation_required: boolean;
  known_issues: KnownIssue[];
  make: string;
  model: string;
  odometer_metric: string;
  plate_number: string;
  remarks?: string;
  type_of_asset: ITypeOfAsset;
  year_of_asset?: number;
  job_reason: string;
}

export interface PersonDetails {
  email: string;
  name: string;
  phone_number: string;
  surname: string;
}

export interface BookingFormData {
  asset_details: AssetDetails;
  collector_details: Nullable<Partial<PersonDetails>>;
  manager_details: Omit<PersonDetails, 'phone_number'>;
  requestor_details: PersonDetails;
}

export interface BookingFormOptions {
  body_types: Array<IDropdownOption>;
  odometer_types: Array<IDropdownOption>;
  type_of_asset: Array<IDropdownOption>;
  type_of_repair: {
    // Note: type_of_asset_name is ITypeOfAsset
    [type_of_asset_name: string]: Array<IDropdownOption>;
  };
  wfp_departments: Array<IDropdownOption>;
  job_reasons: Array<IDropdownOption>;
}

// Booking Details
export interface IBookingFile {
  uuid: string;
  uploaded_by: IFocalPoint;
  file: string;
  file_url: string;
  created: string;
  updated: string;
}

export interface IBodyType {
  asset_type: string;
  code: string;
  description: string;
  latest_sync_time: Nullable<string>;
  uuid: string;
}

export interface ICommentDraft {
  body: string;
}

export interface IDefaultComment {
  body: string;
  author: string;
  uuid: string;
  label: string;
}

export interface IComment {
  body: string;
  created: string;
  created_by: IFocalPoint;
  updated: string;
  uuid: string;
}

export enum IAssetStatusValue {
  EXIST_ON_FMS = 'EXIST_ON_FMS',
  NOT_EXIST_ON_FMS = 'NOT_EXIST_ON_FMS',
  CREATION_ONGOING = 'CREATION_ONGOING',
}

export interface IAssetStatus {
  label: string;
  support_request_number: string;
  value: IAssetStatusValue;
}

export interface IBookingDetails {
  agency_number: Nullable<string>;
  allowed_transitions: DataItem[];
  appointments: Omit<IAppointment, 'booking'>[];
  asset_status: IAssetStatus;
  asset_fms_customer: Nullable<string>;
  body_type: Nullable<IBodyType>;
  collector_details: Nullable<Partial<PersonDetails>>;
  comments: Array<IComment>;
  current_odometer_value: number;
  department_wfp: string;
  fms_request_sent: Nullable<boolean>;
  fms_jobcard_link_request_sent: Nullable<boolean>;
  fms_sync_in_progress: Nullable<boolean>;
  has_spare_parts: boolean;
  is_quotation_required: boolean;
  jobcard: Nullable<IJobcard>;
  known_issues: KnownIssue[];
  make: Nullable<MakeOption>;
  manager_details: Omit<PersonDetails, 'phone_number'>;
  model: Nullable<ModelOption>;
  odometer_metric: string;
  pfi: Nullable<string>;
  pdf: Nullable<string>;
  plate_number: string;
  reference_code: string;
  rejection_remarks: string;
  remarks: string;
  request_number: string;
  requested: string;
  requesting_agency: string;
  requestor_details: PersonDetails;
  state: IBookingStatus;
  type_of_asset: ITypeOfAsset;
  uuid: string;
  year_of_asset: number;
  job_reason: string;
}

export type IBookingDetailsPostData = Partial<
  Omit<IBookingDetails, 'body_type' | 'make' | 'model'> & { body_type: string; make: string; model: string }
>;
