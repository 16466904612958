import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button } from '@components';
import { DataItem } from '@common/interfaces';
import { ArrowIcon } from '@assets/icons';
import { useOutsideClick } from '@common/hooks';
import css from './styles.module.scss';

interface ICheckboxDropdownItem {
  label: string;
  value: string;
  nested?: Array<DataItem>;
}

interface ICheckboxDropdown {
  items?: Array<ICheckboxDropdownItem>;
  selectedItems: Array<DataItem>;
  onChange: Dispatch<SetStateAction<Array<DataItem>>>;
  title: string;

  // Note: Sections feature
  selectedSections?: Array<string>;
  setSelectedSections?: Dispatch<SetStateAction<Array<string>>>;
}

const CheckboxDropdown: FC<ICheckboxDropdown> = ({
  items = [],
  onChange,
  title,
  selectedItems,
  selectedSections = [],
  setSelectedSections,
}) => {
  const [innerSelectedItems, setInnerSelectedItems] = useState<Array<DataItem>>(selectedItems);

  const [filteredItems, setFilteredItems] = useState(items);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openedSections, setOpenedSections] = useState<Array<string>>([]);

  const resetState = (): void => {
    setIsOpen(false);
    setOpenedSections([]);
    setFilteredItems(items);
    setInnerSelectedItems(selectedItems);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => resetState());

  useEffect(() => {
    setFilteredItems(items);
  }, [items.length]);

  useEffect(() => {
    setInnerSelectedItems(selectedItems);
  }, [selectedItems.length]);

  const isActive = innerSelectedItems.length > 0;

  return (
    <div className={css.wrapper} ref={wrapperRef}>
      <div className={`${css.field} ${isActive ? css.active : ''}`} onClick={() => setIsOpen(!isOpen)}>
        <div className={css.value}>
          {title} {isActive ? `(${innerSelectedItems.length})` : null}
        </div>
        <ArrowIcon className={css.arrow} type={isOpen ? 'top' : 'bottom'} />
      </div>
      {isOpen && (
        <div className={css.dropdownBox}>
          <div className={css.content}>
            {filteredItems.length === 0 ? <div className={css.empty}>No items</div> : null}
            {filteredItems.map((item) => {
              const { label, value, nested } = item;
              if (nested) {
                const isOpenedSection = openedSections.find((elem) => elem === value);
                return (
                  <div className={css.section} key={value}>
                    <div className={css.header}>
                      <label className={css.checkbox}>
                        <input
                          className={css.input}
                          type='checkbox'
                          checked={Boolean(selectedSections.find((val) => val === value))}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedSections?.((prevArray) => [...prevArray, value]);
                              setInnerSelectedItems((prevArray) =>
                                [...prevArray, ...nested].filter((elem, index, arr) => arr.indexOf(elem) === index)
                              );
                            } else {
                              setSelectedSections?.((prevArray) => [...prevArray].filter((val) => val !== value));
                              setInnerSelectedItems((prevArray) =>
                                [...prevArray].filter((elem) => !nested.find((el) => el.value === elem.value))
                              );
                            }
                          }}
                        />
                        <span className={css.label}>
                          <span>{label}</span>
                        </span>
                      </label>
                      <Button
                        variant='icon'
                        iconR={<ArrowIcon className={css.arrow} type={isOpenedSection ? 'top' : 'bottom'} />}
                        onClick={() =>
                          setOpenedSections((prevArray) => {
                            if (prevArray.find((elem) => elem === value)) {
                              return [...prevArray].filter((elem) => elem !== value);
                            }
                            return [...prevArray, value];
                          })
                        }
                      />
                    </div>
                    {isOpenedSection && (
                      <div className={css.body}>
                        {nested.map((item) => {
                          const { label, value } = item;
                          return (
                            <label className={css.checkbox} key={value}>
                              <input
                                className={css.input}
                                type='checkbox'
                                checked={Boolean(innerSelectedItems.find((elem) => elem.value === value))}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setInnerSelectedItems((prevArray) => [...prevArray, { label, value }]);
                                  } else {
                                    setInnerSelectedItems((prevArray) =>
                                      [...prevArray].filter((elem) => elem.value !== value)
                                    );
                                  }
                                }}
                              />
                              <span className={css.label}>
                                <span>{label}</span>
                              </span>
                            </label>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              } else {
                return (
                  <label className={css.checkbox} key={value}>
                    <input
                      className={css.input}
                      type='checkbox'
                      checked={Boolean(innerSelectedItems.find((elem) => elem.value === value))}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setInnerSelectedItems((prevArray) => [...prevArray, { label, value }]);
                        } else {
                          setInnerSelectedItems((prevArray) => [...prevArray].filter((elem) => elem.value !== value));
                        }
                      }}
                    />
                    <span className={css.label}>
                      <span>{label}</span>
                    </span>
                  </label>
                );
              }
            })}
          </div>
          <Button
            className={css.applyButton}
            text='Apply'
            onClick={() => {
              setIsOpen(false);
              onChange(innerSelectedItems);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CheckboxDropdown;
