import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js';
import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { ValueItem } from '@common/interfaces';
import css from './styles.module.scss';

interface IBarChart {
  data: ValueItem[];
  title?: string | JSX.Element;
  isHorizontal?: boolean;
  columnColor?: string;
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, {
  id: 'paddingBelowLegends',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeInit: function (chart: any) {
    const originalFit = chart.legend.fit;
    if (chart.config._config.type === 'bar') {
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 15;
      };
    }
  },
});

const BarChart: FC<IBarChart> = ({ data, isHorizontal = true, title, columnColor = '#50D5EB' }) => {
  const filteredData = data.filter(({ value }) => value !== null);

  const options = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    indexAxis: (isHorizontal ? 'y' : 'x') as any,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: isHorizontal,
          color: '#dce0e5',
          borderWidth: 0,
          lineWidth: 1,
        },
        ticks: {
          display: isHorizontal,
          color: '#1d1f20',
          font: {
            size: 14,
          },
        },
      },
      y: {
        grid: {
          display: !isHorizontal,
          color: '#dce0e5',
          borderWidth: 0,
          lineWidth: 1,
        },
        ticks: {
          color: '#1d1f20',
          font: {
            size: 14,
          },
        },
      },
    },
  };

  const calculatedData = {
    labels: filteredData.map(({ label }) => label),
    datasets: [
      {
        data: filteredData.map(({ value }) => value),
        backgroundColor: columnColor,
        barThickness: 16,
      },
    ],
  };

  return (
    <div className={css.wrapper}>
      <div className={css.title}>{title}</div>
      <Bar options={options} data={calculatedData} />
    </div>
  );
};

export default BarChart;
