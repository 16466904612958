import React, { FC } from 'react';
import comCss from '../../styles.module.scss';

interface CounterProps {
  length: number;
  max: number;
}

const Counter: FC<CounterProps> = ({ max, length }) => {
  return <div className={`${comCss.counter} ${length > max ? comCss.overfilled : ''}`}>{`${length}/${max}`}</div>;
};

export default Counter;
