import { useSearchParams } from 'react-router-dom';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useRepository } from './repository.context';
import { useQuery } from 'react-query';
import {
  IDropdownOption,
  IFacility,
  IManagedFacility,
  LocalStorageKey,
  PaginatedResponse,
  QueryParams,
} from '@common/interfaces';
import { useLocalStorage } from '@common/hooks';
import { useAuth } from '@unbooking/ui-auth';

interface FacilityProviderProps {
  children: ReactNode;
}

type FacilityContextType = {
  facility: IFacility;
  facilityId: string;
  setFacilityIdOnMonolith: (facilityId: string) => void;
  managedFacilities: IDropdownOption[];
};

const ALL_FACILITIES_FLAG = 'all';

const defaultFacility = {
  uuid: '',
  services: [],
  country: null,
  city: null,
  work_time: null,
  allowed_agencies: ['WFP', 'UNESCO'],
  name: '',
  agency: '',
  city_name: '',
  country_name: '',
  image: '',
  facility_id_on_hbh: 0,
  fms_workshop_id: '',
  email: '',
  address: '',
  longitude: 0,
  latitude: 0,
};

const FacilityContext = createContext<FacilityContextType>({
  facility: defaultFacility,
  facilityId: '',
  setFacilityIdOnMonolith: () => undefined,
  managedFacilities: [],
});

const FacilityProvider = ({ children }: FacilityProviderProps) => {
  const { isAuthenticated } = useAuth();
  const { facilityRepository, monolithAuthorizedRepository } = useRepository();
  const [searchParams, setSearchParams] = useSearchParams();

  const facilityIdOnMonolithParam = searchParams.get(QueryParams.FACILITY_ID) || '';

  const [facilityIdOnMonolith, setFacilityIdOnMonolith] = useLocalStorage<string>(
    LocalStorageKey.FACILITY_ID_MONOLITH,
    facilityIdOnMonolithParam
  );

  const { data: managedFacilities = [] } = useQuery(
    'managed-facilities',
    () => monolithAuthorizedRepository.getManagedFacilities(),
    {
      enabled: isAuthenticated,
      select: (data: PaginatedResponse<IManagedFacility>) =>
        data.results
          .sort((a, b) => a.city_name.localeCompare(b.city_name))
          .map(({ name, city_name, pk }) => ({
            label: `${city_name} ${name}`,
            value: `${pk}`,
          })) ?? [],
      onSuccess: (data) => {
        if (!facilityIdOnMonolith && !facilityIdOnMonolithParam && data.length > 0) {
          setFacilityIdOnMonolith(data[0].value);
        }
      },
    }
  );

  const { data: facilityDetails, refetch } = useQuery<IFacility>(
    'facility-details',
    () => facilityRepository.getFacility(facilityIdOnMonolithParam || facilityIdOnMonolith),
    {
      enabled: isAuthenticated && Boolean(facilityIdOnMonolith) && facilityIdOnMonolith !== ALL_FACILITIES_FLAG,
    }
  );

  const context = {
    managedFacilities,
    setFacilityIdOnMonolith: (facilityId: string) =>
      setSearchParams({
        [QueryParams.FACILITY_ID]: facilityId,
      }),
    facility: facilityDetails && facilityIdOnMonolith !== ALL_FACILITIES_FLAG ? facilityDetails : defaultFacility,
    facilityId: facilityIdOnMonolith !== ALL_FACILITIES_FLAG ? facilityIdOnMonolith : '',
  };

  useEffect(() => {
    (async () => {
      const facilityId = searchParams.get(QueryParams.FACILITY_ID);
      if (facilityId) {
        setFacilityIdOnMonolith(facilityId);
        await refetch();
      }
    })();
  }, [searchParams]);

  return <FacilityContext.Provider value={context}>{children}</FacilityContext.Provider>;
};

const useFacilityContext = () => useContext(FacilityContext);

export { FacilityProvider, useFacilityContext };
