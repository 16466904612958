import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import {
  DefaultListParams,
  IJobcardListParams,
  IJobcardOptionsListParams,
  IJobcardPendingListParams,
  IJobcardRepository,
  JobcardSyncData,
} from '@common/interfaces';

class JobcardRepository extends AxiosRepository implements IJobcardRepository {
  constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  async getJobcards(facilityId: string, params?: IJobcardListParams) {
    const { data } = await this.get(`/jobcards/`, {
      params: {
        ...params,
        facility_id_on_hbh: facilityId,
      },
    });
    return data;
  }

  async getJobcard(facilityId: string, jobcardId: string) {
    const { data } = await this.get(`/jobcards/${jobcardId}/`, {
      params: {
        facility_id_on_hbh: facilityId,
      },
    });
    return data;
  }

  async getJobcardsPending(facilityId: string, params: IJobcardPendingListParams) {
    const { data } = await this.get(`/jobcards/pending/`, {
      params: {
        ...params,
        facility_id_on_hbh: facilityId,
      },
    });
    return data;
  }

  async getJobcardsOptions(facilityId: string, params: IJobcardOptionsListParams) {
    const { data } = await this.get(`/jobcards/options/`, {
      params: {
        ...params,
        facility_id_on_hbh: facilityId,
      },
    });
    return data;
  }

  async syncJobcardsData(facilityId: string, body?: JobcardSyncData) {
    const { data } = await this.post(`/jobcards/sync-with-fms/`, body, {
      params: {
        facility_id_on_hbh: facilityId,
      },
    });
    return data;
  }

  async getJobcardsCustomers(facilityId: string, params?: DefaultListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/jobcards/customers/`, { params });
    return data;
  }
}

export default JobcardRepository;
