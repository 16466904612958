import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ColumnType } from 'antd/lib/table/interface';
import {
  IBooking,
  IBookingFilters,
  IDropdownOption,
  Nullable,
  PaginatedResponse,
  IBookingListTab,
  DateRange,
  SessionStorageKey,
} from '@common/interfaces';
import { useFacilityContext, useRepository } from '@context';
import {
  StatusDropdown,
  Button,
  CheckboxDropdown,
  Main,
  MonthPicker,
  Page,
  SearchField,
  Table,
  TablePagination,
  Tabs,
} from '@components';
import { ExcelIcon, MarkerIcon, WrenchCarIcon } from '@assets/svg';
import { DEFAULT_SERVER_DATE_FORMAT, DEFAULT_USER_DATE_FORMAT, LINKS } from '@common/constants';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';
import css from './styles.module.scss';
import { useDownloadFile } from '@common/hooks';

const tabList = [
  { name: 'To process', key: IBookingListTab.TO_PROCESS },
  { name: 'In progress', key: IBookingListTab.IN_PROGRESS },
  { name: 'Closed', key: IBookingListTab.CLOSED },
];

const DEFAULT_PAGE_SIZE = 10;

const columns: ColumnType<IBooking>[] = [
  {
    dataIndex: 'request_number',
    key: 'request_number',
    title: 'Request #',
    sorter: true,
  },
  {
    dataIndex: 'requestor',
    key: 'requestor',
    title: 'Requestor',
    render: (_, { requestor_name, requestor_surname }) => `${requestor_name} ${requestor_surname}`,
  },
  {
    dataIndex: 'requesting_agency',
    key: 'requesting_agency',
    title: 'Agency',
    sorter: true,
  },
  {
    dataIndex: 'requested',
    key: 'created',
    title: 'Request date',
    render: (_, { requested }) => format(new Date(requested), DEFAULT_USER_DATE_FORMAT),
    sorter: true,
  },
  {
    dataIndex: 'type_of_asset',
    key: 'type_of_asset',
    title: 'Type',
    sorter: true,
  },
  {
    dataIndex: 'plate_number',
    key: 'plate_number',
    title: 'Plate/ID',
    sorter: true,
  },
  {
    dataIndex: 'current_odometer_value',
    key: 'current_odometer_value',
    title: 'Odometer',
    sorter: true,
    render: (_, { odometer_metric, current_odometer_value }) =>
      `${current_odometer_value.toLocaleString('en')} ${odometer_metric}`,
  },
  {
    dataIndex: 'state',
    key: 'state',
    title: 'Status',
    sorter: true,
    render: (_, { state }) => <StatusDropdown status={state} allowedTransitions={[]} />,
  },
];

const BookingListPage: FC = () => {
  const { facilityId, facility } = useFacilityContext();
  const { bookingRepository } = useRepository();
  const navigate = useNavigate();
  const params = JSON.parse(sessionStorage.getItem(SessionStorageKey.BOOKING_LIST_PARAMS) || '{}') || {};

  const { city_name, country_name, name } = facility;

  const [tab, setTab] = useState<IBookingListTab>((params.tab as IBookingListTab) ?? tabList[0].key);
  const [page, setPage] = useState(params.page || 1);
  const [pageSize, setPageSize] = useState(params.pageSize || DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState(params.order || '');
  const [search, setSearch] = useState('');
  const [agencyFilter, setAgencyFilter] = useState<IDropdownOption[]>(params.agencyFilter || []);
  const [statusFilter, setStatusFilter] = useState<IDropdownOption[]>(params.statusFilter || []);
  const [typeFilter, setTypeFilter] = useState<IDropdownOption[]>(params.typeFilter || []);
  const [dateFilter, setDateFilter] = useState<Nullable<DateRange>>(params.dateFilter || null);

  const { data: bookings, isLoading: isDataLoading } = useQuery<PaginatedResponse<IBooking>>(
    ['bookings', tab, order, page, pageSize, agencyFilter, statusFilter, typeFilter, dateFilter, search],
    () =>
      bookingRepository.getBookings(facilityId, {
        limit: pageSize,
        offset: (page - 1) * 10,
        ordering: order,
        search,
        requesting_agency: agencyFilter.map(({ value }) => value).join(','),
        state: statusFilter.map(({ value }) => value).join(','),
        type_of_asset: typeFilter.map(({ value }) => value).join(','),
        created_after: dateFilter?.created_after,
        created_before: dateFilter?.created_before,
        tab_type: tab,
      })
  );

  const { data: filtersData, isLoading: isFiltersLoading } = useQuery<IBookingFilters>(['booking-filters', tab], () =>
    bookingRepository.getFilters(facilityId, {
      tab_type: tab,
    })
  );

  const { mutate: getExports, isLoading: inProgress } = useMutation(
    'export-bookings',
    () =>
      bookingRepository.exportBookings(facilityId, {
        ordering: order,
        search,
        requesting_agency: agencyFilter.map(({ value }) => value).join(','),
        state: statusFilter.map(({ value }) => value).join(','),
        type_of_asset: typeFilter.map(({ value }) => value).join(','),
        created_after: dateFilter?.created_after,
        created_before: dateFilter?.created_before,
        tab_type: tab,
      }),
    {
      onSuccess: (data) =>
        useDownloadFile(
          data,
          `${name}_${country_name}_${city_name}_${format(new Date(), DEFAULT_SERVER_DATE_FORMAT)}_booking_list.xls`
        ),
    }
  );

  const clearPagination = () => {
    setPage(1);
    setPageSize(DEFAULT_PAGE_SIZE);
    setOrder('');
  };

  const clearFilters = () => {
    setAgencyFilter([]);
    setStatusFilter([]);
    setTypeFilter([]);
    setDateFilter(null);
  };

  const clearAllParams = () => {
    clearPagination();
    clearFilters();
  };

  const tabChange = (tab: string) => {
    setTab(tab as IBookingListTab);
    clearAllParams();
  };

  useEffect(() => {
    sessionStorage.setItem(
      SessionStorageKey.BOOKING_LIST_PARAMS,
      JSON.stringify({
        tab,
        order,
        page,
        pageSize,
        agencyFilter,
        statusFilter,
        typeFilter,
        dateFilter,
      })
    );
  }, [tab, order, page, pageSize, agencyFilter, statusFilter, typeFilter, dateFilter]);

  const place = `${city_name}, ${country_name}`;
  const loading = isDataLoading || isFiltersLoading || inProgress;
  const isFiltered = agencyFilter.length || typeFilter.length || statusFilter.length || dateFilter;

  const results = bookings?.results || [];

  return (
    <Main>
      <Page>
        <div className={css.top}>
          <div>
            <div className={css.title}>Bookings</div>
            <div className={css.info}>
              <div>
                <WrenchCarIcon />
                {name}
              </div>
              <div>
                <MarkerIcon />
                {place}
              </div>
            </div>
          </div>
          {results.length > 0 ? (
            <Button
              text='Export Data'
              variant='forest'
              disabled={inProgress}
              iconR={<ExcelIcon />}
              onClick={() => getExports()}
            />
          ) : null}
        </div>
        <Tabs tabList={tabList} tabKey={tab} onTabChange={tabChange} />
        <section className={css.tab}>
          <div className={css.topPanel}>
            <div className={css.filters}>
              <span>Filters:</span>
              <CheckboxDropdown
                items={filtersData?.agencies}
                onChange={(options) => {
                  clearPagination();
                  setAgencyFilter(options);
                }}
                selectedItems={agencyFilter}
                title='Agency'
              />
              <MonthPicker
                showDates
                initialFrom={dateFilter?.created_after ? new Date(dateFilter.created_after) : null}
                initialTo={dateFilter?.created_before ? new Date(dateFilter.created_before) : null}
                setMonths={(from, to) => {
                  if (from && to) {
                    setDateFilter({
                      created_after: format(from, DEFAULT_SERVER_DATE_FORMAT),
                      created_before: format(to, DEFAULT_SERVER_DATE_FORMAT),
                    });
                  }
                }}
              />
              <CheckboxDropdown
                items={filtersData?.type_of_asset}
                onChange={(options) => {
                  clearPagination();
                  setTypeFilter(options);
                }}
                selectedItems={typeFilter}
                title='Type'
              />
              <CheckboxDropdown
                items={filtersData?.states}
                onChange={(options) => {
                  clearPagination();
                  setStatusFilter(options);
                }}
                selectedItems={statusFilter}
                title='Status'
              />
              {isFiltered ? <Button text='Reset filters' variant='text' onClick={() => clearAllParams()} /> : null}
            </div>
            <SearchField onChange={setSearch} value={search} placeholder='Search booking...' />
          </div>
          <Table
            className={css.table}
            columns={columns}
            data={results}
            loading={loading}
            onChangeColumnOrder={setOrder}
            onRowClick={({ uuid }) => navigate(LINKS.booking(uuid))}
          />
          <TablePagination
            page={page}
            onChangePage={setPage}
            totalCount={bookings?.count}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
          />
        </section>
      </Page>
    </Main>
  );
};

export default BookingListPage;
