import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button } from '@components';
import { DataItem } from '@common/interfaces';
import { ArrowIcon } from '@assets/icons';
import { useOutsideClick } from '@common/hooks';
import css from './styles.module.scss';

interface ICheckboxDropdownFullItem {
  label: string;
  value: string;
}

interface ICheckboxDropdownFull {
  items?: Array<ICheckboxDropdownFullItem>;
  selectedItems: Array<DataItem>;
  onChange: Dispatch<SetStateAction<Array<DataItem>>>;
  label: string;
  placeholder?: string;
}

const CheckboxDropdownFull: FC<ICheckboxDropdownFull> = ({
  items = [],
  onChange,
  label,
  selectedItems,
  placeholder = 'Any',
}) => {
  const [innerSelectedItems, setInnerSelectedItems] = useState<DataItem[]>(selectedItems);

  const [filteredItems, setFilteredItems] = useState(items);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const resetState = (): void => {
    setIsOpen(false);
    setFilteredItems(items);
    setInnerSelectedItems(selectedItems);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => resetState());

  useEffect(() => {
    setFilteredItems(items);
  }, [items.length]);

  useEffect(() => {
    setInnerSelectedItems(selectedItems);
  }, [selectedItems.length]);

  const isActive = innerSelectedItems.length > 0;

  const isAllSelected = innerSelectedItems.length === filteredItems.length;

  return (
    <div>
      {label ? <div className={css.fieldLabel}>{label}</div> : null}
      <div className={css.wrapper} ref={wrapperRef}>
        <div className={`${css.field} ${isActive ? css.active : ''}`} onClick={() => setIsOpen(!isOpen)}>
          <div className={css.value}>
            {innerSelectedItems.length
              ? `Selected ${isAllSelected ? 'all' : innerSelectedItems.length} item(s)`
              : placeholder}
          </div>
          <ArrowIcon className={css.arrow} type={isOpen ? 'top' : 'bottom'} />
        </div>
        {isOpen && (
          <div className={css.dropdownBox}>
            <div className={css.content}>
              {filteredItems.length === 0 ? <div className={css.empty}>No items</div> : null}
              {filteredItems.map((item) => {
                const { label, value } = item;
                return (
                  <label className={css.checkbox} key={value}>
                    <input
                      className={css.input}
                      type='checkbox'
                      checked={Boolean(innerSelectedItems.find((elem) => elem.value === value))}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setInnerSelectedItems((prevArray) => [...prevArray, { label, value }]);
                        } else {
                          setInnerSelectedItems((prevArray) => [...prevArray].filter((elem) => elem.value !== value));
                        }
                      }}
                    />
                    <span className={css.label}>
                      <span>{label}</span>
                    </span>
                  </label>
                );
              })}
            </div>
            <Button
              className={css.applyButton}
              text='Apply'
              onClick={() => {
                setIsOpen(false);
                onChange(innerSelectedItems);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckboxDropdownFull;
