import React, { FC } from 'react';
import { useField } from 'informed';
import { SuccessIcon } from '@assets/svg';
import { IDropdownOption, InformedFieldState } from '@common/interfaces';
import css from './styles.module.scss';

interface IRadioOptionField {
  name: string;
  options: Array<IDropdownOption>;
  isRow?: boolean;
  onChange?: () => void;
}

const RadioOptionField: FC<IRadioOptionField> = (props) => {
  const { options, name, onChange, isRow = false } = props;

  const { fieldState, fieldApi, render, ref } = useField(props);

  const { value: fieldValue, error, showError } = fieldState as InformedFieldState<IDropdownOption>;

  const { setValue, setTouched } = fieldApi;

  return render(
    <div ref={ref} className={`${css.wrapper} ${isRow ? css.row : ''}`}>
      {options.map(({ value, label }, idx) => {
        const isChecked = fieldValue?.value === value;
        const id = `${name}-${value}`;

        return (
          <label htmlFor={id} key={idx} className={css.field}>
            <input
              className={css.input}
              type='radio'
              id={id}
              name={name}
              checked={isChecked}
              onChange={(e) => {
                setValue({ value, label });
                setTouched(true, e);
                onChange?.();
              }}
            />
            <div className={css.item}>
              {label}
              <SuccessIcon />
            </div>
          </label>
        );
      })}
      {showError ? <div className={css.error}>{error}</div> : null}
    </div>
  );
};

export default RadioOptionField;
