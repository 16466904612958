import { LocalStorageKey } from '@common/interfaces';

const setupNonSSOSession = (email: string, key: string) => {
  localStorage.setItem(LocalStorageKey.USER_EMAIL, JSON.stringify(email));
  localStorage.setItem(LocalStorageKey.USER_NAME, JSON.stringify(email));
  localStorage.setItem(LocalStorageKey.IS_AUTHENTICATED, String(true));
  localStorage.setItem(LocalStorageKey.FIRST_LOGIN_ATTEMPT, String(false));
  localStorage.setItem(LocalStorageKey.LOGIN_IN_PROGRESS, String(false));
  localStorage.setItem(LocalStorageKey.X_USER_TOKEN, key);

  window.location.href = '/';
};

export default setupNonSSOSession;
