import React, { FC } from 'react';
import css from './styles.module.scss';

interface ITextArea {
  disabled?: boolean;
  onChange: (value: string) => void;
  value: string;
}

const TextArea: FC<ITextArea> = ({ disabled, onChange, value }) => {
  return (
    <textarea value={value} onChange={(e) => onChange(e.target.value)} className={css.input} disabled={disabled} />
  );
};

export default TextArea;
