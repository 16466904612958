import React, { FC, ReactNode } from 'react';
import { Table as AntTable, TablePaginationConfig } from 'antd';
import { ColumnType, FilterValue } from 'antd/lib/table/interface';
import { Empty, Loader } from '@components';
import css from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataItem = any;

interface ITable {
  className?: string;
  columns: ColumnType<DataItem>[];
  data?: DataItem[];
  loading?: boolean;
  onChangeColumnOrder?: (order: string) => void;
  onRowClick?: (record: DataItem) => void;
  rowKey?: string;
  summary?: (data: readonly DataItem[]) => ReactNode;
}

const Table: FC<ITable> = ({
  className,
  columns,
  data = [],
  loading = false,
  onChangeColumnOrder,
  onRowClick,
  rowKey = 'uuid',
  summary,
}) => {
  return (
    <Loader loading={loading}>
      <AntTable
        locale={{ emptyText: <Empty text='No items' /> }}
        className={`${css.table} ${className}`}
        columns={columns}
        summary={summary}
        dataSource={data}
        pagination={false} // Always use TablePagination component for server side pagination
        rowKey={rowKey}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(_pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: any) => {
          const order = `${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`;
          onChangeColumnOrder && onChangeColumnOrder(order);
        }}
        onRow={(record) => {
          return {
            onClick: () => onRowClick && onRowClick(record),
          };
        }}
      />
    </Loader>
  );
};

export default Table;
