import React, { FC, ReactElement } from 'react';
import { Tip } from '@components';
import { Nullable } from '@common/interfaces';
import css from './styles.module.scss';

export type IStatusLabelVariant = 'green' | 'grey' | 'orange' | 'blue' | 'red';

interface IStatusLabel {
  variant: IStatusLabelVariant;
  text: string;
  tip?: Nullable<ReactElement>;
}

const StatusLabel: FC<IStatusLabel> = ({ variant, text, tip = null }) => {
  return (
    <Tip isVisible={Boolean(tip)} content={tip} className={`${css.label} ${css[variant]}`}>
      {text}
    </Tip>
  );
};

export default StatusLabel;
