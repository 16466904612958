import React, { Dispatch, FC, SetStateAction } from 'react';
import { IYupSchema, STEPS } from '@pages/BookingFormPage/consts';
import { Multistep } from 'informed';
import PhoneField from '../PhoneField';
import InformedField from '../InformedField';
import Subtitle from '../Subtitle';
import Navigation from '../Navigation';
import comCss from '../../styles.module.scss';
import { UserIcon } from '@assets/svg';
import { User } from '@common/interfaces';
import { TextField } from '@components';

interface DetailsStepProps {
  countryCode?: string;
  setYupSchema: Dispatch<SetStateAction<IYupSchema>>;
  user: User;
}

const DetailsStep: FC<DetailsStepProps> = ({ countryCode, setYupSchema, user }) => {
  const { first_name, last_name, organisation, email } = user;

  const preferredCountries = countryCode ? [countryCode] : undefined;

  // Note: we use initialValue here, because initialValues on Form makes loop rendering

  return (
    <Multistep.Step step={STEPS.DETAILS}>
      <Subtitle text='Your details' icon={<UserIcon />} />

      <div className={comCss.row}>
        <InformedField label='Name *'>
          <TextField name='requestor_name' initialValue={first_name} disabled />
        </InformedField>
        <InformedField label='Last name *'>
          <TextField name='requestor_surname' initialValue={last_name} disabled />
        </InformedField>
      </div>

      <div className={comCss.row}>
        <InformedField label='Organisation *'>
          <TextField name='requesting_agency' initialValue={organisation?.short_name} disabled />
        </InformedField>
        <InformedField label='Email *'>
          <TextField name='requestor_email' initialValue={email} disabled />
        </InformedField>
      </div>

      <InformedField label='Phone number *'>
        <PhoneField name='requestor_phone_number' preferredCountries={preferredCountries} key={countryCode} />
      </InformedField>

      <div className={comCss.rowTitle}>Authorizing Manager information</div>

      <div className={comCss.row}>
        <InformedField label='Name *'>
          <TextField name='manager_name' />
        </InformedField>
        <InformedField label='Last name *'>
          <TextField name='manager_surname' />
        </InformedField>
      </div>

      <InformedField label='Email *'>
        <TextField name='manager_email' />
      </InformedField>

      <div className={comCss.rowTitle}>Authorizing person to deliver/collect the asset (optional)</div>

      <div className={comCss.row}>
        <InformedField label='Name'>
          <TextField name='collector_name' />
        </InformedField>
        <InformedField label='Last name'>
          <TextField name='collector_surname' />
        </InformedField>
      </div>

      <div className={comCss.row}>
        <InformedField label='Phone number'>
          <PhoneField name='collector_phone_number' preferredCountries={preferredCountries} key={countryCode} />
        </InformedField>
        <InformedField label='Email'>
          <TextField name='collector_email' />
        </InformedField>
      </div>

      <Navigation setYupSchema={setYupSchema} />
    </Multistep.Step>
  );
};

export default DetailsStep;
