import axios, { AxiosInstance } from 'axios';

const monolithClientInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_HBH_API}/v2/`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Api-Key ${process.env.REACT_APP_HBH_KEY}`,
  },
});

export default monolithClientInstance;
