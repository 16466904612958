import React, { FC } from 'react';
import css from './styles.module.scss';

interface ICheckbox {
  checked: boolean;
  label?: string;
  onChange: (checked: boolean) => void;
}

const Checkbox: FC<ICheckbox> = ({ checked, label = '', onChange }) => {
  return (
    <label className={css.checkbox}>
      <input className={css.input} type='checkbox' checked={checked} onChange={(e) => onChange(e.target.checked)} />
      <span className={css.label}>
        <span>{label}</span>
      </span>
    </label>
  );
};

export default Checkbox;
