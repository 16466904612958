import { getDate, getHours, getMinutes, getMonth, getYear, set } from 'date-fns';

const combineDateAndTime = (date: Date, time: Date) => {
  const year = getYear(date);
  const month = getMonth(date);
  const day = getDate(date);

  const hours = getHours(time);
  const minutes = getMinutes(time);

  const combinedDate = set(new Date(), {
    year: year,
    month: month,
    date: day,
    hours: hours,
    minutes: minutes,
    seconds: 0,
    milliseconds: 0,
  });

  return combinedDate;
};
export default combineDateAndTime;
