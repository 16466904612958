import React, { FC, useState } from 'react';
import { Button, Modal, TextArea } from '@components';
import { CloseRoundIcon } from '@assets/svg';
import { IBookingDetailsPostData, IBookingStatus } from '@common/interfaces';
import css from './styles.module.scss';

interface IRejectionModal {
  closeModal: () => void;
  requestor?: string;
  updateBooking: (data: IBookingDetailsPostData) => void;
}

const RejectionModal: FC<IRejectionModal> = ({ closeModal, requestor = '', updateBooking }) => {
  const [rejectionRemarks, setRejectionRemarks] = useState('');

  return (
    <Modal title='Confirm booking rejection' closeModal={closeModal}>
      <Modal.Content>
        <div className={css.note}>Rejection remarks for the requestor</div>
        <div className={css.subNote}>These remarks will be shared via email to {requestor}</div>
        <TextArea value={rejectionRemarks} onChange={setRejectionRemarks} />
      </Modal.Content>
      <Modal.Footer>
        <Button
          text='Cancel'
          variant='transparent-negative'
          onClick={() => {
            closeModal();
            setRejectionRemarks('');
          }}
        />
        <Button
          text='Confirm rejection'
          iconR={<CloseRoundIcon />}
          variant='alizarin'
          disabled={rejectionRemarks.length === 0}
          onClick={() => {
            closeModal();
            setRejectionRemarks('');
            updateBooking({ state: IBookingStatus.REJECTED, rejection_remarks: rejectionRemarks });
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default RejectionModal;
