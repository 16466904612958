import { useMemo } from 'react';
import { LocalStorageKey, QueryParams, UserType } from '@common/interfaces';
import useLocalStorage from './useLocalStorage';

const useUserType = function () {
  const query = new URLSearchParams(window.location.search);
  const isNonFederatedParam = query.get(QueryParams.IS_NON_FEDERATED_USER);
  const isNonFederatedUser = isNonFederatedParam?.toLowerCase() === 'true';
  const isFederatedUser = isNonFederatedParam?.toLowerCase() === 'false';

  const initialValue = isNonFederatedParam
    ? isNonFederatedUser
      ? UserType.NON_FEDERATED
      : isFederatedUser
      ? UserType.SSO
      : UserType.UNKNOWN
    : UserType.UNKNOWN;

  const [userType, setUserType] = useLocalStorage<UserType>(LocalStorageKey.USER_TYPE, initialValue);

  return useMemo(
    () => ({
      userType,
      setUserType,
    }),
    [userType, setUserType]
  );
};

export default useUserType;
