/* eslint-disable @typescript-eslint/no-explicit-any */

const debounce = (func: (...args: any) => any, timeout = 500) => {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export default debounce;
