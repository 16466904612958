import axios, { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { IFacilityRepository } from '@common/interfaces';

class FacilityRepository extends AxiosRepository implements IFacilityRepository {
  constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  // Note: get locally with clean instance of axios
  async getGeoJSON() {
    const { data } = await axios.get('/countries.geo.json');
    return data;
  }

  async getFacilities() {
    const { data } = await this.get('/facilities/');
    return data;
  }

  async getFacility(facilityId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/`);
    return data;
  }

  async getFocalPoints(facilityId: string) {
    // Note: if we need more focal points - we should make loading options
    const { data } = await this.get(`/facilities/${facilityId}/focal-points/`, {
      params: {
        limit: 100,
      },
    });
    return data;
  }
}

export default FacilityRepository;
