import React, { Dispatch, FC, Fragment, SetStateAction, cloneElement, useEffect, useRef, useState } from 'react';
import css from './styles.module.scss';
import {
  addDays,
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  isToday,
  isWithinInterval,
  startOfDay,
  startOfWeek,
  subDays,
} from 'date-fns';
import { ArrowLeftIcon, ArrowRightIcon } from '@assets/svg';
import { Button } from '@components';
import { getLocalDate, roundTimeToNearestHalfHour } from '@common/utils';
import { Spin } from 'antd';
import { DEFAULT_END_TIME, DEFAULT_START_TIME } from '@common/constants';

interface BaseEvent {
  end: string;
  start: string;
  uuid: string;
}

interface IEvent extends BaseEvent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface ICalendar {
  disabledDays?: number[];
  endTime?: string;
  eventTemplate: JSX.Element;
  events?: Array<IEvent>;
  loading?: boolean;
  startTime?: string;
  setStartPlannerDate: Dispatch<SetStateAction<Date>>;
  startPlannerDate: Date;
}

interface ITimeline {
  isEmpty?: boolean;
}

const checkOverlapping = (intervalToCheck: IEvent, events: IEvent[]) => {
  return events.findIndex((event) => {
    if (intervalToCheck.uuid === event.uuid) return false;

    const startDateTime = new Date(event.start);
    const endDateTime = new Date(event.end);
    const startToCheck = new Date(intervalToCheck.start);
    const endToCheck = new Date(intervalToCheck.end);

    return (
      isWithinInterval(startDateTime, { start: startToCheck, end: endToCheck }) ||
      isWithinInterval(endDateTime, { start: startToCheck, end: endToCheck }) ||
      (isBefore(startDateTime, startToCheck) && isAfter(endDateTime, endToCheck))
    );
  });
};

const generateTimeArray = (startTime: string, endTime: string) => {
  const startDate = new Date(`2000-01-01T${startTime}`);
  const endDate = new Date(`2000-01-01T${endTime}`);
  const timeArray = [];

  const tmpDate = new Date(startDate);
  tmpDate.setMinutes(0);
  if (startDate.getMinutes() > 0) {
    tmpDate.setHours(tmpDate.getHours() + 1);
  }

  while (tmpDate.getHours() < endDate.getHours()) {
    timeArray.push(format(tmpDate, 'HH:mm'));
    tmpDate.setHours(tmpDate.getHours() + 1);
  }

  return timeArray;
};

const Calendar: FC<ICalendar> = ({
  disabledDays = [],
  endTime = DEFAULT_END_TIME,
  eventTemplate,
  events = [],
  loading = false,
  setStartPlannerDate,
  startPlannerDate,
  startTime = DEFAULT_START_TIME,
}) => {
  const today = startOfDay(new Date());
  const monday = startOfWeek(startOfDay(new Date()), { weekStartsOn: 1 });

  const startTimeRounded = roundTimeToNearestHalfHour(startTime);
  const endTimeRounded = roundTimeToNearestHalfHour(endTime);

  const [fullHeight, setFullHeight] = useState(0);

  const currentWeek = [
    startPlannerDate,
    addDays(startPlannerDate, 1),
    addDays(startPlannerDate, 2),
    addDays(startPlannerDate, 3),
    addDays(startPlannerDate, 4),
    addDays(startPlannerDate, 5),
    addDays(startPlannerDate, 6),
  ];

  const Timeline: FC<ITimeline> = ({ isEmpty = false }) => {
    const timeline = generateTimeArray(startTimeRounded, endTimeRounded);

    const [, startTimeMinutes] = startTimeRounded.split(':').map(Number);
    const [, endTimeMinutes] = endTimeRounded.split(':').map(Number);

    return (
      <div className={css.times}>
        {startTimeMinutes > 0 ? <div className={css.time}></div> : null}
        {timeline.map((t) => {
          return (
            <Fragment key={t}>
              <div className={`${css.time} ${css.timeStart}`}>{isEmpty ? '' : t}</div>
              <div className={css.time}></div>
            </Fragment>
          );
        })}
        {endTimeMinutes > 0 ? (
          <div className={`${css.time} ${css.timeStart}`}>{isEmpty ? '' : `${endTimeRounded.split(':')[0]}:00`}</div>
        ) : null}
      </div>
    );
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef?.current) setFullHeight(containerRef.current.offsetHeight);
  }, [startTime, endTime]);

  const info = (
    <>
      <b>{`${format(startPlannerDate, 'MMMM dd')}-${format(currentWeek[6], 'dd')}`}</b>{' '}
      {format(startPlannerDate, 'yyyy')}
    </>
  );

  return (
    <Spin spinning={loading}>
      <div className={css.wrapper}>
        <div className={css.navigation}>
          <Button variant='transparent-negative' text='Today' onClick={() => setStartPlannerDate(monday)} />
          <Button
            variant='icon'
            iconR={<ArrowLeftIcon />}
            onClick={() => setStartPlannerDate(subDays(startPlannerDate, 7))}
          />
          <Button
            variant='icon'
            iconR={<ArrowRightIcon />}
            onClick={() => setStartPlannerDate(addDays(startPlannerDate, 7))}
          />
          <div className={css.info}>{info}</div>
          <div className={css.legend}>
            <div className={`${css.check} ${css.in}`}>Check-in</div>
            <div className={`${css.check} ${css.out}`}>Check-out</div>
          </div>
        </div>
        <div className={css.labels}>
          {currentWeek.map((date) => {
            const isActive = isToday(date);
            const label = isActive ? (
              <>
                {format(date, 'E')}
                <span className={css.active}>{format(date, 'dd')}</span>
              </>
            ) : (
              format(date, 'E dd')
            );
            return (
              <div key={date.toISOString()} className={css.label}>
                {label}
              </div>
            );
          })}
        </div>
        <div className={css.frame}>
          <div className={css.timeline}>
            <Timeline />
          </div>
          <div className={css.container} ref={containerRef}>
            {currentWeek.map((date) => {
              const workDayStart = new Date(`${format(date, 'yyyy-MM-dd')}T${startTimeRounded}`);
              const workDayEnd = new Date(`${format(date, 'yyyy-MM-dd')}T${endTimeRounded}`);

              const isPast = isBefore(date, today);
              const isDisabled = disabledDays.includes(date.getDay());

              const currentDayEvents = events.filter((event) => {
                const start = getLocalDate(event.start);
                const end = getLocalDate(event.end);

                return (
                  isWithinInterval(start, { start: workDayStart, end: workDayEnd }) ||
                  isWithinInterval(end, { start: workDayStart, end: workDayEnd })
                );
              });

              return (
                <div key={date.toISOString()} className={`${css.day} ${isPast || isDisabled ? css.disabled : ''}`}>
                  <Timeline isEmpty />
                  {currentDayEvents.map((event, idx) => {
                    const { start, end, uuid } = event;

                    const fromDayStartDuration = differenceInMinutes(getLocalDate(start), workDayStart);
                    const fullDayDuration = differenceInMinutes(workDayEnd, workDayStart);
                    const eventDuration = differenceInMinutes(getLocalDate(end), getLocalDate(start));
                    const overlappingIndex = checkOverlapping(event, currentDayEvents);

                    const top = `${(fromDayStartDuration / fullDayDuration) * fullHeight}px`;
                    const height = `${(eventDuration / fullDayDuration) * fullHeight}px`;
                    const isHalfWidth = overlappingIndex !== -1;
                    const left = overlappingIndex > idx ? 0 : 'auto';
                    const right = overlappingIndex < idx ? 0 : 'auto';

                    return (
                      <div
                        key={uuid}
                        className={`${css.event} ${isHalfWidth ? css.halfWidth : ''}`}
                        style={{ top, height, right, left }}
                      >
                        {cloneElement(eventTemplate, { ...event })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Calendar;
