import {
  AnalyticsPage,
  BookMaintenancePage,
  BookingDetailsPage,
  BookingFormPage,
  BookingListPage,
  BookingPlannerPage,
  BookingSuccessPage,
  DocumentListPage,
  EntryPage,
  GenerateInvoicePage,
  GeneratePFIPage,
  GenerateStatementPage,
  NoAccessPage,
  NotFoundPage,
  PlannerPage,
} from '@pages';
import { URLS } from '@common/constants';

export default [
  // Base
  {
    path: URLS.NOT_FOUND,
    name: 'Not Found',
    Component: NotFoundPage,
  },
  {
    path: URLS.FORBIDDEN,
    name: 'Forbidden',
    Component: NoAccessPage,
  },

  // Platform
  // Note: The home page is missing because it is on the CMS side.
  {
    path: URLS.BOOK_MAINTENANCE,
    name: 'Book Maintenance',
    Component: BookMaintenancePage,
  },
  {
    path: URLS.BOOK_MAINTENANCE_FORM,
    name: 'Booking Form',
    Component: BookingFormPage,
  },
  {
    path: URLS.BOOK_MAINTENANCE_SUCCESS,
    name: 'Booking Success',
    Component: BookingSuccessPage,
  },

  // Digital Office
  {
    path: URLS.DO,
    name: 'Digital Office',
    Component: EntryPage,
  },
  {
    path: URLS.ANALYTICS,
    name: 'Analytics',
    Component: AnalyticsPage,
  },
  {
    path: URLS.BOOKINGS,
    name: 'Booking List',
    Component: BookingListPage,
  },
  {
    path: URLS.BOOKING,
    name: 'Booking Details',
    Component: BookingDetailsPage,
  },
  {
    path: URLS.BOOKING_PLANNER,
    name: 'Booking Planner',
    Component: BookingPlannerPage,
  },
  {
    path: URLS.GENERATE_PFI,
    name: 'Generate PFI',
    Component: GeneratePFIPage,
  },
  {
    path: URLS.DOCUMENTS,
    name: 'Document List',
    Component: DocumentListPage,
  },
  {
    path: URLS.GENERATE_STATEMENT,
    name: 'Generate Statement',
    Component: GenerateStatementPage,
  },
  {
    path: URLS.GENERATE_INVOICE,
    name: 'Generate Invoice',
    Component: GenerateInvoicePage,
  },
  {
    path: URLS.PLANNER,
    name: 'Planner',
    Component: PlannerPage,
  },
];
