import React, { FC, useCallback, useState } from 'react';
import { debounce } from '@common/utils';
import css from './styles.module.scss';
import { CloseIcon, SearchIcon } from '@assets/svg';
import { Button } from '@components';

interface ISearchField {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  className?: string;
}

const SearchField: FC<ISearchField> = ({ onChange, value, placeholder = 'Search...', className = '' }) => {
  const [innerValue, setInnerValue] = useState(value);

  const delayedOnChange = useCallback(
    debounce((val) => onChange(val)),
    []
  );

  const handleChange = (val: string) => {
    setInnerValue(val);
    delayedOnChange(val);
  };

  return (
    <div className={`${css.wrapper} ${className}`}>
      <SearchIcon className={css.icon} />
      <input
        className={css.input}
        type='text'
        onChange={(e) => handleChange(e.target.value)}
        value={innerValue}
        placeholder={placeholder}
      />
      {innerValue.length > 0 ? (
        <Button className={css.button} variant='icon' iconR={<CloseIcon />} onClick={() => handleChange('')} />
      ) : null}
    </div>
  );
};

export default SearchField;
