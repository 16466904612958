import React, { FC, useEffect, useRef, useState } from 'react';
import {
  format,
  startOfYear,
  endOfYear,
  addMonths,
  isBefore,
  isAfter,
  startOfMonth,
  endOfMonth,
  subYears,
  addYears,
} from 'date-fns';
import { Button } from '@components';
import { Nullable } from '@common/interfaces';
import { DEFAULT_USER_DATE_FORMAT, DEFAULT_MONTH_PICKER_FORMAT } from '@common/constants';
import { ArrowIcon, ChevronIcon } from '@assets/icons';
import css from './styles.module.scss';
import { useOutsideClick } from '@common/hooks';

interface IMonthPicker {
  initialFrom: Nullable<Date>;
  initialTo: Nullable<Date>;
  setMonths: (from: Nullable<Date>, to: Nullable<Date>) => void;
  isClearing?: boolean;
  showDates?: boolean;
  triggerText?: string;
  triggerStyle?: 'default' | 'box';
  label?: string;
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const MonthPicker: FC<IMonthPicker> = ({
  initialFrom = null,
  initialTo = null,
  setMonths,
  label,
  isClearing = false,
  showDates = false,
  triggerText = 'Request dates',
  triggerStyle = 'default',
}) => {
  const [open, setOpen] = useState(false);

  const defaultRightDate = initialTo ? startOfYear(initialTo) : startOfYear(new Date());

  const defaultLeftDate =
    initialFrom && initialTo && startOfYear(initialFrom).getFullYear() === endOfYear(initialTo).getFullYear()
      ? startOfYear(initialFrom)
      : initialFrom
      ? startOfYear(initialFrom)
      : subYears(startOfYear(new Date()), 1);

  const [leftDate, setLeftDate] = useState(defaultLeftDate);
  const [rightDate, setRightDate] = useState(defaultRightDate);

  const [startDate, setStartDate] = useState<Nullable<Date>>(initialFrom);
  const [endDate, setEndDate] = useState<Nullable<Date>>(initialTo);

  const handleMonthClick = (idx: number, current: Date) => {
    const newDate = addMonths(current, idx);
    if ((!startDate && !endDate) || (startDate && endDate)) {
      setStartDate(newDate);
      setEndDate(null);
    } else {
      if (startDate && isBefore(newDate, startDate)) {
        setEndDate(startDate);
        setStartDate(newDate);
      } else {
        setEndDate(endOfMonth(newDate));
      }
    }
  };

  const handleClose = () => {
    setLeftDate(defaultLeftDate);
    setRightDate(defaultRightDate);
    setStartDate(initialFrom);
    setEndDate(initialTo);
    setOpen(false);
  };

  const handleApply = () => {
    setMonths(startDate, endDate);
    setOpen(false);
  };

  const handleClear = () => {
    setLeftDate(defaultLeftDate);
    setRightDate(defaultRightDate);
    setStartDate(null);
    setEndDate(null);
    setMonths(null, null);
    setOpen(false);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, handleClose);

  useEffect(() => {
    setStartDate(initialFrom);
    setEndDate(initialTo);
  }, [initialFrom, initialTo]);

  const triggerContent =
    startDate && endDate
      ? `${format(startDate, DEFAULT_MONTH_PICKER_FORMAT)} - ${format(endDate, DEFAULT_MONTH_PICKER_FORMAT)}`
      : triggerText;

  const isActive = startDate && endDate;

  return (
    <div>
      {label ? <div className={css.label}>{label}</div> : null}
      <div className={css.wrapper} ref={wrapperRef}>
        <div
          className={`${css.trigger} ${css[triggerStyle]} ${isActive ? css.active : ''}`}
          onClick={() => setOpen(!open)}
        >
          <div className={css.text}>{triggerContent}</div>
          <ArrowIcon className={css.arrow} type={open ? 'top' : 'bottom'} />
        </div>
        {open && (
          <div className={css.popup}>
            {showDates ? (
              <div className={css.dates}>
                {startDate || endDate ? (
                  <>
                    From {startDate ? format(startDate, DEFAULT_USER_DATE_FORMAT) : '-----'} to{' '}
                    {endDate ? format(endDate, DEFAULT_USER_DATE_FORMAT) : '-----'}
                  </>
                ) : (
                  'Select dates'
                )}
              </div>
            ) : null}
            <div className={css.container}>
              <div className={css.box}>
                <div className={css.yearSelector}>
                  <Button
                    variant='icon'
                    iconR={<ChevronIcon type='left' />}
                    onClick={() => setLeftDate((prev) => subYears(prev, 1))}
                  />
                  <div className={css.year}>
                    <b>{startOfYear(leftDate).getFullYear()}</b>
                  </div>
                  <Button
                    variant='icon'
                    iconR={<ChevronIcon type='right' />}
                    onClick={() => {
                      const newLeftDate = addYears(leftDate, 1);
                      setLeftDate(newLeftDate);
                      if (endOfYear(rightDate).getFullYear() === newLeftDate.getFullYear()) {
                        setRightDate(addYears(newLeftDate, 1));
                      }
                    }}
                  />
                </div>
                <div className={css.monthsWrapper}>
                  {months.map((month, idx) => {
                    const selectedMonth = startDate?.getMonth();
                    const currentMonth = addMonths(leftDate, idx);
                    const isActive =
                      (selectedMonth === idx &&
                        startOfYear(startDate || new Date()).getFullYear() === leftDate.getFullYear()) ||
                      (endDate &&
                        currentMonth &&
                        isAfter(currentMonth, startOfMonth(startDate || new Date())) &&
                        isBefore(currentMonth, endOfMonth(endDate || new Date())));

                    return (
                      <div
                        className={`${css.month} ${isActive ? css.active : ''}`}
                        key={idx}
                        onClick={() => handleMonthClick(idx, leftDate)}
                      >
                        {month}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={css.box}>
                <div className={css.yearSelector}>
                  <Button
                    variant='icon'
                    iconR={<ChevronIcon type='left' />}
                    onClick={() => {
                      const newRightDate = subYears(rightDate, 1);
                      setRightDate(newRightDate);
                      if (startOfYear(leftDate).getFullYear() === newRightDate.getFullYear()) {
                        setLeftDate(subYears(newRightDate, 1));
                      }
                    }}
                  />
                  <div className={css.year}>
                    <b>{startOfYear(rightDate).getFullYear()}</b>
                  </div>
                  <Button
                    variant='icon'
                    iconR={<ChevronIcon type='right' />}
                    onClick={() => setRightDate((prev) => addYears(prev, 1))}
                  />
                </div>
                <div className={css.monthsWrapper}>
                  {months.map((month, idx) => {
                    const selectedMonth = startDate?.getMonth();
                    const currentMonth = addMonths(rightDate, idx);
                    const isActive =
                      (selectedMonth === idx &&
                        startOfYear(startDate || new Date()).getFullYear() === rightDate.getFullYear()) ||
                      (endDate &&
                        currentMonth &&
                        isAfter(currentMonth, startOfMonth(startDate || new Date())) &&
                        isBefore(currentMonth, endOfMonth(endDate || new Date())));

                    return (
                      <div
                        className={`${css.month} ${isActive ? css.active : ''}`}
                        key={idx}
                        onClick={() => handleMonthClick(idx, rightDate)}
                      >
                        {month}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={css.buttons}>
              {isClearing ? <Button text='Clear' className={css.button} onClick={handleClear} /> : null}
              <Button text='Apply' className={css.button} onClick={handleApply} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthPicker;
