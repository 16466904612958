import type { FC } from 'react';
import { URLS } from '@common/constants';
import { Button, Main } from '@components';
import css from './styles.module.scss';

const NoAccessPage: FC = () => {
  return (
    <Main>
      <div className={css.container}>
        <div className={css.title}>
          Sorry, you don&apos;t have access to this page.
          <hr />
          <p>If you are sure you have valid permissions please contact support.</p>
        </div>
        <div className={css.buttons}>
          <Button link={URLS.ROOT} text='Go to home page' className={css.button} />
          <Button link='mailto:noreply.humanitarianbooking@wfp.org' text='Contact us' className={css.button} />
        </div>
      </div>
    </Main>
  );
};

export default NoAccessPage;
