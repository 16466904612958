import { Nullable, WorkSchedule } from '@common/interfaces';

const getDisabledDays = (schedule: Nullable<WorkSchedule>) => {
  if (!schedule) return [];

  const disabledDays: number[] = [];
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = schedule;

  if (!monday) disabledDays.push(1);
  if (!tuesday) disabledDays.push(2);
  if (!wednesday) disabledDays.push(3);
  if (!thursday) disabledDays.push(4);
  if (!friday) disabledDays.push(5);
  if (!saturday) disabledDays.push(6);
  if (!sunday) disabledDays.push(0);

  return disabledDays;
};

export default getDisabledDays;
