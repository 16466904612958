import { LocalStorageKey } from '@common/interfaces';

const removeNonSSOSession = () => {
  localStorage.removeItem(LocalStorageKey.ID_TOKEN);
  localStorage.removeItem(LocalStorageKey.USER_EMAIL);
  localStorage.removeItem(LocalStorageKey.USER_NAME);
  localStorage.removeItem(LocalStorageKey.IS_AUTHENTICATED);
  localStorage.removeItem(LocalStorageKey.FIRST_LOGIN_ATTEMPT);
  localStorage.removeItem(LocalStorageKey.LOGIN_IN_PROGRESS);
  localStorage.removeItem(LocalStorageKey.USER_TYPE);
  localStorage.removeItem(LocalStorageKey.X_USER_TOKEN);

  window.location.href = '/';
};

export default removeNonSSOSession;
