import { ArcElement, Chart as ChartJS, Tooltip, TooltipItem } from 'chart.js';
import React, { FC, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { stringToColour } from '@common/utils';
import { ValueItemColorized } from '@common/interfaces';
import css from './styles.module.scss';
import { Empty } from '@components';
import { DEFAULT_CHART_COLORS } from '@common/constants';

interface IDoughnutChart {
  title: string;
  data: ValueItemColorized[];
}

ChartJS.register(ArcElement, Tooltip);

const getPercentage = (value: number, total: number) => {
  if (!value || !total) {
    return '0%';
  }
  return `${((value / total) * 100).toFixed(2)}%`;
};

const DoughnutChart: FC<IDoughnutChart> = ({ title, data }) => {
  if (data.length === 0) return <Empty />;

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (item: TooltipItem<'doughnut'>) => {
            return `${item.label} - ${item.parsed} (${getPercentage(item.parsed, total)})`;
          },
        },
      },
    },
    borderWidth: 2,
    cutout: '65%',
  };

  const total = useMemo(() => {
    return data.reduce((accumulator, { value }) => {
      return accumulator + (value || 0);
    }, 0);
  }, [data]);

  const colorizedData = data.map(({ backgroundColor, label, ...rest }, idx) => {
    return {
      ...rest,
      label,
      backgroundColor: backgroundColor || DEFAULT_CHART_COLORS[idx] || stringToColour(label),
    };
  });

  const calculatedData = {
    labels: colorizedData.map(({ label }) => label),
    datasets: [
      {
        data: colorizedData.map(({ value }) => value),
        backgroundColor: colorizedData.map(({ backgroundColor }) => backgroundColor),
        borderColor: '#fff',
      },
    ],
  };

  return (
    <div className={css.wrapper}>
      <div className={css.title}>{title}</div>
      <div className={css.chart}>
        <div className={css.doughnut}>
          <Doughnut data={calculatedData} options={options} />
        </div>
        <div className={css.labels}>
          {colorizedData.map(({ label, value, backgroundColor }, index) => {
            if (!label) return null;

            return (
              <div className={css.label} key={index}>
                <div className={css.square} style={{ backgroundColor }}></div>
                {`${label} ${value ? ` - ${Intl.NumberFormat('en-US').format(value)}` : ''}`}
                {value ? <span>({getPercentage(value, total)})</span> : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;
