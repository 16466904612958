/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactElement, SyntheticEvent, useEffect } from 'react';
import { useField } from 'informed';
import Select, { GroupBase, OptionProps, OptionsOrGroups, components } from 'react-select';
import { ComponentProps, UseAsyncPaginateParams, withAsyncPaginate } from 'react-select-async-paginate';
import Creatable, { CreatableProps } from 'react-select/creatable';
import { InformedFieldState, Nullable } from '@common/interfaces';
import { ChevronIcon } from '@assets/icons';
import css from './styles.module.scss';

type AsyncPaginateCreatableProps<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean
> = CreatableProps<OptionType, IsMulti, Group> &
  UseAsyncPaginateParams<OptionType, Group, Additional> &
  ComponentProps<OptionType, Group, IsMulti>;

type AsyncPaginateCreatableType = <
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean = false
>(
  props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>
) => ReactElement;

interface IDropdownOption {
  label: string;
  value: string;
}

export interface IDropdown {
  loadOptions?: (
    search: string,
    loadedOptions: OptionsOrGroups<unknown, GroupBase<unknown>>
  ) => Promise<{
    options: IDropdownOption[];
    hasMore: boolean;
  }>;
  className?: string;
  defaultOptions?: IDropdownOption[];
  isCreatable?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  name?: string;
  noOptionsMessage?: (inputValue: string) => Nullable<string>;
  onChange?: (value: any) => void;
  onCreateOption?: (value: string) => void;
  options?: IDropdownOption[];
  placeholder?: string;
  value?: Nullable<IDropdownOption | IDropdownOption[]>;
  variant?: 'default' | 'status';
  Option?: (props: OptionProps) => JSX.Element;
  menuPlacement?: string;
}

const getDropdownElement = (isCreatable: boolean, loadOptions: boolean) => {
  const BasicDropdown = isCreatable ? Creatable : Select;
  const Element: AsyncPaginateCreatableType = loadOptions
    ? (withAsyncPaginate(BasicDropdown) as AsyncPaginateCreatableType)
    : BasicDropdown;

  return Element;
};

interface IField {
  name: string;
  config: any;
}

const Field: FC<IField> = ({ name, config }) => {
  const { fieldApi, fieldState, ref, render } = useField({ name });

  const { value, error, showError } = fieldState as InformedFieldState<Nullable<IDropdownOption | IDropdownOption[]>>;

  const { setValue, setTouched } = fieldApi;

  const { value: defaultValue, isCreatable, loadOptions, onChange, options } = config;

  const fieldConfig = {
    ...config,
    value: !value ? '' : typeof value === 'string' ? options?.find((option: any) => option.value === value) : value,
    onChange: (selectedValue: IDropdownOption | IDropdownOption[]) => {
      setValue(selectedValue);
      onChange?.(selectedValue);
    },
    onBlur: (e: SyntheticEvent<Element, Event>) => setTouched(true, e),
    ref,
    ...(loadOptions
      ? {
          ref: undefined,
          innerRef: ref,
        }
      : {}),
  };

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  const FinalDropdown = getDropdownElement(isCreatable, Boolean(loadOptions));

  return render(
    <div className={`${css.field} ${showError ? css.fieldError : ''}`}>
      <FinalDropdown {...fieldConfig} />
      {showError ? <div className={css.error}>{error}</div> : null}
    </div>
  );
};

const Dropdown: FC<IDropdown> = ({
  className,
  defaultOptions,
  isCreatable = false,
  isDisabled = false,
  isSearchable = false,
  loadOptions,
  name,
  noOptionsMessage,
  onChange,
  onCreateOption,
  options,
  placeholder = 'Select...',
  value,
  variant = 'default',
  menuPlacement = 'auto',
  Option,
}) => {
  const styles = {
    option: (provided: any, state: any) => {
      return {
        ...provided,
        backgroundColor: state.data.backgroundColor,
        color: state.data.color || '#fff',
      };
    },
    control: (provided: any, state: any) => {
      const option = state.getValue();
      return {
        ...provided,
        boxShadow: 'none',
        backgroundColor: option[0]?.backgroundColor,
        color: option[0]?.color || '#fff',
      };
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const DropdownIndicator: FC<any> = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronIcon type='top' />
      </components.DropdownIndicator>
    );
  };

  const config = {
    className: `select-field ${className} var-${variant}`,
    classNamePrefix: 'rsf',
    components: {
      IndicatorSeparator: null,
      DropdownIndicator,
      ...(Option ? { Option } : {}),
    },
    placeholder,
    menuPlacement,
    // menuPortalTarget: document.body, // Note: can help with z-index issues
    styles,
    options,
    defaultOptions,
    value,
    isSearchable,
    isCreatable,
    onCreateOption,
    isDisabled,
    onChange,
    noOptionsMessage,
    ...(loadOptions
      ? {
          loadOptions,
          debounceTimeout: 500,
        }
      : {}),
  } as any;

  const FinalDropdown = getDropdownElement(isCreatable, Boolean(loadOptions));

  // Note: If have name prop - enable form field
  if (name) return <Field config={config} name={name} />;

  return <FinalDropdown {...config} />;
};

export default Dropdown;
