import React, { Dispatch, FC, SetStateAction } from 'react';
import { IYupSchema, yupSchemas, STEPS } from '@pages/BookingFormPage/consts';
import { useFormApi, useMultistepApi } from 'informed';
import { ArrowLeftIcon, ArrowRightIcon, SuccessIcon } from '@assets/svg';
import comCss from '../../styles.module.scss';
import { Button } from '@components';

interface NavigationProps {
  setYupSchema: Dispatch<SetStateAction<IYupSchema>>;
  confirmEnabled?: boolean;
}

const Navigation: FC<NavigationProps> = ({ setYupSchema, confirmEnabled = false }) => {
  const { getFormState } = useFormApi();
  const { next, previous, getCurrentStep, getNextStep, getPreviousStep } = useMultistepApi();

  const currentStep = getCurrentStep();
  const prevStep = getPreviousStep();
  const nextStep = getNextStep() as unknown as string;

  const onPrev = () => {
    previous();
    setYupSchema(yupSchemas[prevStep]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onNext = () => {
    next();
    const { valid } = getFormState();
    if (valid) setYupSchema(yupSchemas[nextStep]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={comCss.navigation}>
      {currentStep !== STEPS.DETAILS ? (
        <Button
          text='Back'
          iconL={<ArrowLeftIcon />}
          className={`${comCss.button} ${comCss.buttonPrev}`}
          onClick={onPrev}
          type='button'
          variant='transparent'
        />
      ) : null}
      {currentStep !== STEPS.REVIEW ? (
        <Button
          text='Next'
          iconR={<ArrowRightIcon />}
          className={`${comCss.button} ${comCss.buttonNext}`}
          onClick={onNext}
          type='button'
        />
      ) : (
        <Button
          text='Confirm'
          iconR={<SuccessIcon />}
          type='submit'
          variant='forest'
          className={`${comCss.button} ${comCss.buttonConfirm}`}
          disabled={!confirmEnabled}
        />
      )}
    </div>
  );
};

export default Navigation;
