import { AxiosInstance } from 'axios';

class AxiosRepository {
  public constructor(protected axiosClient: AxiosInstance, authClient?: AxiosInstance) {
    this.axiosClient.defaults.headers = {
      ...axiosClient.defaults.headers,
      ...(authClient ? authClient?.defaults.headers : {}),
    };

    if (authClient) {
      this.axiosClient.interceptors.request = authClient.interceptors.request;
      this.axiosClient.interceptors.response = authClient.interceptors.response;
    }
  }

  public get = this.axiosClient.get;
  public patch = this.axiosClient.patch;
  public post = this.axiosClient.post;
  public delete = this.axiosClient.delete;
}

export default AxiosRepository;
