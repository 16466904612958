import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { IDraftStatement, IStatementListParams, IStatementOtherCost, IStatementRepository } from '@common/interfaces';

class StatementRepository extends AxiosRepository implements IStatementRepository {
  constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  async getStatements(facilityId: string, params?: IStatementListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/statements/`, { params });
    return data;
  }

  async createStatement(facilityId: string, statement: IDraftStatement) {
    const { data } = await this.post(`/facilities/${facilityId}/statements/`, statement);
    return data;
  }

  async deleteStatement(facilityId: string, statementId: string) {
    const { data } = await this.delete(`/facilities/${facilityId}/statements/${statementId}/`);
    return data;
  }

  async getOptions(facilityId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/statements/options/`);
    return data;
  }

  async generateStatementPdf(facilityId: string, statementId: string) {
    const { data } = await this.post(`/facilities/${facilityId}/statements/${statementId}/generate/`);
    return data;
  }

  async addOtherCost(facilityId: string, statementId: string, item: Partial<IStatementOtherCost>) {
    const { data } = await this.post(`/facilities/${facilityId}/statements/${statementId}/other-costs/`, item);
    return data;
  }

  async updateOtherCost(facilityId: string, statementId: string, item: Partial<IStatementOtherCost>) {
    const { data } = await this.patch(
      `/facilities/${facilityId}/statements/${statementId}/other-costs/${item.uuid}/`,
      item
    );
    return data;
  }

  async deleteOtherCost(facilityId: string, statementId: string, itemId: string) {
    const { data } = await this.delete(`/facilities/${facilityId}/statements/${statementId}/other-costs/${itemId}/`);
    return data;
  }
}

export default StatementRepository;
