import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { IMonolithRepository } from '@common/interfaces';
import { monolithClientInstance } from '@common/clients';

class MonolithRepository extends AxiosRepository implements IMonolithRepository {
  public constructor(protected authClient?: AxiosInstance) {
    super(monolithClientInstance, authClient);
  }

  async postLogin(email: string, password: string): Promise<{ key: string }> {
    const { data } = await this.post(`/rest-auth/login/`, {
      email,
      password,
    });
    return data;
  }

  async postVerifyB2cUser(email: string): Promise<{ is_b2c_user: boolean }> {
    const { data } = await this.post(`/core/b2c/users/verify/`, { email });
    return data;
  }

  async getSsoAllowedDomains(): Promise<{ [provider: string]: string[] }> {
    const { data } = await this.get(`/core/sso-allowed-domains/`);
    return data;
  }
}

export default MonolithRepository;
