import { LocalStorageKey } from '@common/interfaces';
import axios, { AxiosInstance } from 'axios';

const token = localStorage.getItem(LocalStorageKey.X_USER_TOKEN);

export const apiNonSSOClientInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
  },
});

export default apiNonSSOClientInstance;
