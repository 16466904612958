import React, { FC, useState } from 'react';
import { useFacilityContext, useRepository } from '@context';
import { Calendar, EventBlock, Main, Page } from '@components';
import { CheckInIcon, CheckOutIcon, MarkerIcon, WrenchCarIcon } from '@assets/svg';
import { useQuery } from 'react-query';
import { AppointmentsTodayStat, IAppointment, PaginatedResponse } from '@common/interfaces';
import { mapAppointments } from '@components/EventBlock';
import { DEFAULT_SERVER_DATE_FORMAT } from '@common/constants';
import { addDays, format, startOfDay, startOfWeek } from 'date-fns';
import { toast } from 'react-toastify';
import css from './styles.module.scss';
import { getDisabledDays } from '@common/utils';

const MAX_EVENTS_PER_WEEK = 100;

const PlannerPage: FC = () => {
  const { facilityId, facility } = useFacilityContext();
  const { appointmentRepository } = useRepository();

  const defaultStartPlannerDate = startOfWeek(startOfDay(new Date()), { weekStartsOn: 1 });

  const [startPlannerDate, setStartPlannerDate] = useState(defaultStartPlannerDate);

  const { data: appointments, isLoading: isAppointmentsLoading } = useQuery<PaginatedResponse<IAppointment>>(
    ['appointments', startPlannerDate],
    () =>
      appointmentRepository.getAppointments(facilityId, {
        appointment_datetime_before: format(addDays(startPlannerDate, 6), DEFAULT_SERVER_DATE_FORMAT),
        appointment_datetime_after: format(startPlannerDate, DEFAULT_SERVER_DATE_FORMAT),
        limit: MAX_EVENTS_PER_WEEK,
      })
  );

  const { data: appointmentsToday, isLoading: isAppointmentsTodayLoading } = useQuery<AppointmentsTodayStat>(
    'appointments-today',
    () => appointmentRepository.getAppointmentsForToday(facilityId)
  );

  if (appointments?.next)
    toast.warning(
      `Too many events on the page. Contact technical support. Only ${MAX_EVENTS_PER_WEEK} events are displayed.`
    );

  const events = mapAppointments(appointments?.results);
  const place = facility ? `${facility.city_name}, ${facility.country_name}` : '';
  const disabledDays = facility ? getDisabledDays(facility.work_time) : [];

  return (
    <Main loading={isAppointmentsTodayLoading}>
      <Page>
        <div className={css.top}>
          <div>
            <div className={css.title}>Planner</div>
            <div className={css.info}>
              <div>
                <WrenchCarIcon />
                {facility.name}
              </div>
              <div>
                <MarkerIcon />
                {place}
              </div>
            </div>
          </div>
          {appointmentsToday ? (
            <div className={css.stat}>
              <div className={css.count}>
                <div className={css.value}>
                  <CheckInIcon className={css.checkIn} /> {appointmentsToday.checkins}
                </div>
                <div className={css.label}>Check-ins today</div>
              </div>
              <div className={css.count}>
                <div className={css.value}>
                  <CheckOutIcon className={css.checkOut} /> {appointmentsToday.checkouts}
                </div>
                <div className={css.label}>Check-outs today</div>
              </div>
            </div>
          ) : null}
        </div>
        <Calendar
          startPlannerDate={startPlannerDate}
          setStartPlannerDate={setStartPlannerDate}
          disabledDays={disabledDays}
          endTime={facility.work_time?.end_time}
          eventTemplate={<EventBlock />}
          events={events}
          loading={isAppointmentsLoading}
          startTime={facility.work_time?.start_time}
        />
      </Page>
    </Main>
  );
};

export default PlannerPage;
