import React, { FC } from 'react';
import css from './styles.module.scss';
import { Nullable } from '@common/interfaces';

interface IBox {
  title: string;
  icon: JSX.Element;
  width?: 'full' | 'half' | 'quarter' | 'threeQuarters';
  withoutPadding?: boolean;
  buttons?: Nullable<JSX.Element>;
  status?: Nullable<JSX.Element>;
}

const Box: FC<IBox> = ({ children, icon, title, width = 'full', buttons = null, status = null }) => {
  return (
    <div className={`${css.box} ${css[width]}`}>
      <div className={css.title}>
        <div className={css.icon}>{icon}</div>
        {title}
        <div className={css.additional}>
          {buttons}
          {status}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Box;
