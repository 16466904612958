import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CMSArticle, CMSButton, CMSButtonGroup, CMSCard, CMSSection } from '@common/interfaces';
import hexToRgb from '@common/utils/hextoRgb';
import { ArrowIcon } from '@assets/icons';
import './styles.scss';

enum StreamFieldTypes {
  button_group = 'button_group',
  article = 'article',
  card = 'card',
  section = 'section',
  label = 'label',
}

export interface IStreamFieldObject {
  type: StreamFieldTypes;
  id: string;
  value: CMSButtonGroup | CMSArticle | CMSSection | CMSCard;
}

interface IStringFieldProps {
  data: IStreamFieldObject[];
  classNames?: {
    [K in StreamFieldTypes]?: string;
  };
}

const Button: FC<CMSButton> = ({ color, path, icon, label }) => {
  return (
    <div onClick={() => ((window as Window).location = path)} className='button' style={{ backgroundColor: color }}>
      {icon ? <img style={{ width: icon.width, height: icon.height }} src={icon.full_url} alt={icon.alt} /> : null}
      <p className='text'>{label}</p>
    </div>
  );
};

const StreamField: FC<IStringFieldProps> = ({ data, classNames }): JSX.Element => {
  const streamField = data;
  const result = [];
  const navigate = useNavigate();

  for (let i = 0; i < streamField.length; i++) {
    const field = streamField[i];

    //Atoms - No content inside:

    if (field.type === 'button_group') {
      const { buttons, label } = field.value as CMSButtonGroup;

      result.push(
        <div key={i} className={`buttons-section ${classNames && classNames[field.type]}`}>
          {label ? (
            <p className='section-label'>
              {label} {label && <hr />}
            </p>
          ) : null}
          <div className='buttons'>
            {buttons.map((button: CMSButton, key) => (
              <Button key={key} color={button.color} path={button.path} icon={button.icon} label={button.label} />
            ))}
          </div>
        </div>
      );
    } else if (field.type === 'article') {
      const { body, label } = field.value as CMSArticle;

      result.push(
        <div key={i} className='text-section'>
          {label ? <p className='title'>{label}</p> : null}
          <p className='description' dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      );
    } else if (field.type === 'card') {
      const { additional_link, background, body, color, label } = field.value as CMSCard;
      const { r, g, b } = hexToRgb(color) || {};

      result.push(
        <div
          style={{
            backgroundColor: `rgba(${r},${g},${b}, 0.9)`,
            backgroundImage: `url(${background?.full_url})`,
          }}
          key={i}
          className='card-container'
        >
          {label && (
            <div style={{ backgroundColor: color }} className='card-title'>
              <p>{label}</p>
            </div>
          )}
          <div className='card-content'>
            <div className='card-text' dangerouslySetInnerHTML={{ __html: body }} />
            {additional_link ? (
              <div className='link'>
                <div
                  onClick={() =>
                    !additional_link.is_external
                      ? navigate(additional_link.url)
                      : window.open(additional_link.url, '_blank')
                  }
                >
                  {additional_link.label} <ArrowIcon type='top' />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    // Containers - Mapping atoms to components and wrap them
    else if (field.type === 'section') {
      const { value } = field as unknown as CMSSection;

      // It's something to fix on backend. Label is inside section children...
      const sectionObject = value.find((object) => object.type === 'label');

      result.push(
        <div key={i} className={`${classNames && classNames[field.type]} section-container`}>
          {sectionObject && <p className='label-component'>{sectionObject?.value as unknown as string}</p>}
          <div className='section-content'>
            <StreamField data={value} />
          </div>
        </div>
      );
    }
  }

  return <>{result}</>;
};

export default StreamField;
