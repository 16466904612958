import React, { FC } from 'react';
import css from './styles.module.scss';

interface IDefaultField {
  text?: string | JSX.Element;
}

const DefaultField: FC<IDefaultField> = ({ text = '' }) => {
  return <div className={css.field}>{text}</div>;
};

export default DefaultField;
