import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CMSNavigation } from '@common/interfaces';
import css from './styles.module.scss';

interface INavigationMobile {
  navigation?: CMSNavigation[];
  isActive: boolean;
  closeMenu: () => void;
}

const NavigationMobile: FC<INavigationMobile> = ({ navigation = [], isActive, children, closeMenu }) => {
  const { pathname } = useLocation();
  return (
    <nav className={`${css.navigation} ${isActive ? css.active : ''}`}>
      {navigation.map(({ icon, link_url, link_title, open_in_new_tab }, idx) => {
        const target = open_in_new_tab ? '_blank' : '_self';
        const isActive = pathname === link_url;
        return (
          <Link
            onClick={closeMenu}
            to={link_url}
            key={idx}
            className={`${css.link} ${isActive ? css.active : ''}`}
            target={target}
          >
            {icon && <img src={icon} alt='' />}
            {link_title}
          </Link>
        );
      })}
      {children}
    </nav>
  );
};

export default NavigationMobile;
