import React, { FC } from 'react';
import css from './styles.module.scss';

interface IInformedField {
  children: JSX.Element | Array<JSX.Element>;
  label: string;
  className?: string;
}

const InformedField: FC<IInformedField> = ({ children, label, className }) => {
  return (
    <div className={`${css.field} ${className}`}>
      <div className={css.label}>{label}</div>
      {children}
    </div>
  );
};

export default InformedField;
