import { IAppointmentURLType } from '@common/interfaces';

export const URLS = {
  // Base
  ROOT: '/',
  NOT_FOUND: '/not-found',
  FORBIDDEN: '/forbidden',

  // Platform
  BOOK_MAINTENANCE: '/book-maintenance',
  BOOK_MAINTENANCE_FORM: '/book-maintenance/:id',
  BOOK_MAINTENANCE_SUCCESS: '/book-maintenance/success',

  // Digital Office
  ANALYTICS: '/digital-office/analytics',
  BOOKING: '/digital-office/bookings/:id',
  BOOKINGS: '/digital-office/bookings',
  BOOKING_PLANNER: '/digital-office/bookings/:id/planner/:type',
  DO: '/digital-office',
  DOCUMENTS: '/digital-office/documents',
  GENERATE_INVOICE: '/digital-office/documents/new/invoice',
  GENERATE_PFI: '/digital-office/bookings/:id/pfi/:pfiId',
  GENERATE_STATEMENT: '/digital-office/documents/new/statement',
  PLANNER: '/digital-office/planner',
};

export const LINKS = {
  booking: (bookingId: string) => `/digital-office/bookings/${bookingId}/`,
  pfi: (bookingId: string, pfiId: string) => `/digital-office/bookings/${bookingId}/pfi/${pfiId}/`,
  plannerCheckIn: (bookingId: string) =>
    `/digital-office/bookings/${bookingId}/planner/${IAppointmentURLType.CHECK_IN}`,
  plannerCheckOut: (bookingId: string) =>
    `/digital-office/bookings/${bookingId}/planner/${IAppointmentURLType.CHECK_OUT}`,
};

export const DEFAULT_START_TIME = '09:00:00';
export const DEFAULT_END_TIME = '18:00:00';

export const DEFAULT_TIME_INTERVAL = 60; // react-datepicker
export const DEFAULT_DATE_PICKER_FORMAT = 'dd/MM/yyyy'; // react-datepicker

export const DEFAULT_SERVER_DATE_FORMAT = 'yyyy-MM-dd'; // date-fns
export const DEFAULT_SERVER_MONTH_FORMAT = 'yyyy-MM'; // date-fns
export const DEFAULT_SERVER_TIME_FORMAT = 'HH:mm:ss'; // date-fns
export const DEFAULT_USER_DATE_FORMAT = 'd MMM yyyy'; // date-fns
export const DEFAULT_USER_MONTH_FORMAT = 'MMMM yyyy'; // date-fns
export const DEFAULT_USER_DATE_TIME_FORMAT = 'd MMMM yyyy, HH:mm'; // date-fns
export const DEFAULT_MONTH_PICKER_FORMAT = 'MMM/yyyy'; // date-fns

export const MIN_ASSET_YEAR = 1900;
export const MAX_ASSET_YEAR = new Date().getFullYear();

export const DEFAULT_CHART_COLORS = [
  '#009EDB',
  '#5ED8AE',
  '#D5BF53',
  '#FDA2CA',
  '#50D5EB',
  '#F7B825',
  '#FF707C',
  '#89EAA8',
  '#FDA987',
];
