import React, { FC } from 'react';
import { Button, Main } from '@components';
import { URLS } from '@common/constants';
import { useLocation } from 'react-router-dom';
import css from './styles.module.scss';
import { useAuth } from '@unbooking/ui-auth';

interface LocationState {
  isDO?: boolean;
}

const NotFoundPage: FC = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const data = location.state as LocationState;
  const rootPage = isAuthenticated ? 'home page' : 'login page';

  return (
    <Main withoutAuth={!isAuthenticated}>
      <div className={css.container}>
        <div className={css.title}>Sorry, you don&apos;t have permission to access this section at this time</div>
        <hr />
        <p>
          You may want to head back to the {rootPage}. <br /> If you think something is broken, contact us.
        </p>
        <div className={css.buttons}>
          <Button link={data?.isDO ? URLS.DO : URLS.ROOT} text={`Go to ${rootPage}`} className={css.button} />
          <Button link='mailto:noreply.humanitarianbooking@wfp.org' text='Contact us' className={css.button} />
        </div>
      </div>
    </Main>
  );
};

export default NotFoundPage;
