import { FC, useState } from 'react';
import { IAssetStatusValue, IBookingDetails, IBookingDetailsPostData } from '@common/interfaces';
import Box from '../Box';
import { CloseIcon, DocDownloadIcon, EditIcon, RequestIcon, SaveIcon } from '@assets/svg';
import { Button, Dropdown, Loader, Modal, Tip } from '@components';
import { useFacilityContext, useRepository } from '@context';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { mapPlateNumbers } from '@common/utils';
import css from './styles.module.scss';
import { IAssetDropdownOption } from '@common/utils/mapPlateNumbers';
import { useMutation } from 'react-query';
import { useDownloadFile } from '@common/hooks';

interface IDetailsBlock {
  bookingDetails: IBookingDetails;
  updateBooking: (data: IBookingDetailsPostData) => void;
}

interface IDetailsRow {
  label: string;
  value?: string | Array<string> | JSX.Element;
}

interface IDetailsPlateNumberRow {
  label: string;
  value: string;
  isAssetEditable: boolean;
  updateBooking: (data: IBookingDetailsPostData) => void;
}

const DetailsRow: FC<IDetailsRow> = ({ label, value = '-' }) => {
  return (
    <div className={css.row}>
      <div className={css.label}>{label}</div>
      {Array.isArray(value) ? (
        <ol className={css.list}>
          {value.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ol>
      ) : (
        <div className={css.value}>{value}</div>
      )}
    </div>
  );
};

const DetailsPlateNumberRow: FC<IDetailsPlateNumberRow> = ({ label, isAssetEditable, value = '-', updateBooking }) => {
  const { bookingRepository } = useRepository();
  const defaultValue = { label: value, value };

  const [edit, setEdit] = useState(false);
  const [plateNumber, setPlateNumber] = useState<IAssetDropdownOption>(defaultValue);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const loadAssetOptions = async (search: string, loadedOptions: OptionsOrGroups<unknown, GroupBase<unknown>>) => {
    const { results, next } = await bookingRepository.getAssets({
      limit: 10,
      search,
      offset: loadedOptions.length,
    });

    return {
      options: mapPlateNumbers(results),
      hasMore: Boolean(next),
    };
  };

  return (
    <div className={css.row}>
      <div className={css.label}>{label}</div>
      <div className={css.value}>
        {edit ? (
          <Dropdown
            value={plateNumber}
            loadOptions={loadAssetOptions}
            isSearchable
            isCreatable
            placeholder='Search...'
            onChange={setPlateNumber}
          />
        ) : (
          value
        )}
      </div>
      {isAssetEditable ? (
        <div className={css.actions}>
          {edit ? (
            <>
              <Tip text='Discard'>
                <Button variant='text' iconL={<CloseIcon />} onClick={() => setEdit(false)} />
              </Tip>
              <Tip text='Save'>
                <Button
                  variant='text'
                  iconL={<SaveIcon />}
                  onClick={() => {
                    if (plateNumber.__isNew__) {
                      updateBooking({ plate_number: plateNumber.value });
                      setEdit(false);
                    } else if (plateNumber.make && plateNumber.model) {
                      setShowConfirmModal(true);
                    }
                  }}
                />
              </Tip>
            </>
          ) : (
            <Button text='Edit' variant='text' iconL={<EditIcon />} onClick={() => setEdit(true)} />
          )}
        </div>
      ) : null}
      {showConfirmModal ? (
        <Modal
          className={css.modal}
          title='Confirm modification of make and model'
          closeModal={() => setShowConfirmModal(false)}
        >
          <Modal.Content>
            Make will be changed to <b>{plateNumber.make?.label}</b> and model will be changed to{' '}
            <b>{plateNumber.model?.label}</b> according to plate number <b>{plateNumber.label}</b> related data.
          </Modal.Content>
          <Modal.Footer>
            <Button
              text='Cancel'
              variant='transparent-negative'
              onClick={() => {
                setShowConfirmModal(false);
              }}
            />
            <Button
              text='Confirm'
              onClick={() => {
                updateBooking({
                  plate_number: plateNumber.value,
                  make: plateNumber.make?.value,
                  model: plateNumber.model?.value,
                  current_odometer_value: plateNumber.odometer_value
                    ? parseFloat(plateNumber.odometer_value.replace(',', ''))
                    : undefined,
                  odometer_metric: plateNumber.odometer_metric,
                  year_of_asset: plateNumber.year ? parseInt(plateNumber.year) : undefined,
                });
                setShowConfirmModal(false);
                setEdit(false);
              }}
            />
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
};

const DetailsBlock: FC<IDetailsBlock> = ({ bookingDetails, updateBooking }) => {
  const { bookingRepository } = useRepository();
  const { facilityId } = useFacilityContext();

  const {
    asset_status,
    collector_details,
    current_odometer_value,
    fms_jobcard_link_request_sent,
    fms_request_sent,
    has_spare_parts,
    job_reason,
    jobcard,
    known_issues,
    manager_details,
    odometer_metric,
    pdf,
    plate_number,
    request_number,
    remarks,
    requestor_details,
    uuid: bookingId,
  } = bookingDetails;

  const { mutate: getSummaryPdf, isLoading: inProgress } = useMutation(
    'summary-pdf',
    () => bookingRepository.getBookingSummary(facilityId, bookingId),
    {
      onSuccess: (data) => useDownloadFile(data, `${request_number}-summary.pdf`),
    }
  );

  const isAssetEditable =
    !jobcard &&
    !fms_request_sent &&
    !fms_jobcard_link_request_sent &&
    asset_status.value !== IAssetStatusValue.CREATION_ONGOING;

  return (
    <Box
      title='Request summary'
      icon={<RequestIcon />}
      width='threeQuarters'
      buttons={<Button text='Download' iconR={<DocDownloadIcon />} variant='text' onClick={() => getSummaryPdf()} />}
    >
      <Loader loading={inProgress}>
        <div className={css.container}>
          <div className={css.box}>
            <div className={css.sub}>
              <div className={css.title}>Requestor contacts</div>
              <DetailsRow label='Email' value={requestor_details.email} />
              <DetailsRow label='Phone number' value={requestor_details.phone_number} />
            </div>
            <div className={css.sub}>
              <div className={css.title}>Asset details</div>
              <DetailsPlateNumberRow
                isAssetEditable={isAssetEditable}
                label='Plate/ID'
                value={plate_number}
                updateBooking={updateBooking}
              />
              <DetailsRow
                label='Current odometer'
                value={`${current_odometer_value.toLocaleString('en')} ${odometer_metric}`}
              />
            </div>
            <div className={css.sub}>
              <div className={css.title}>Authorizing staff</div>
              <DetailsRow
                label='Authorizing manager details'
                value={
                  <>
                    {`${manager_details.name} ${manager_details.surname}`}
                    <br />
                    {manager_details.email}
                  </>
                }
              />
              {collector_details ? (
                <DetailsRow
                  label='Person authorized to deliver/collect the asset'
                  value={
                    <>
                      {collector_details.name} {collector_details.surname}
                      <br />
                      {collector_details.email} {collector_details.phone_number}
                    </>
                  }
                />
              ) : null}
            </div>
          </div>
          <div className={css.box}>
            <div className={css.sub}>
              <div className={css.title}>Intervention details</div>
              <DetailsRow label='Spare parts provided by client' value={has_spare_parts ? 'Yes' : 'No'} />
              <DetailsRow
                label='Known faults to be checked'
                value={known_issues.map(({ text, type_of_repair }) => `${text} / ${type_of_repair}`)}
              />
              <DetailsRow label='Job reason' value={job_reason} />
              <DetailsRow label='Remarks' value={remarks} />
            </div>
          </div>
        </div>
      </Loader>
    </Box>
  );
};

export default DetailsBlock;
