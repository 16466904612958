import React, { FC, ReactNode } from 'react';
import { Button, Loader } from '@components';
import css from './styles.module.scss';
import { CloseIcon } from '@assets/svg';

interface IModal {
  children: ReactNode;
  className?: string;
  closeModal: () => void;
  loading?: boolean;
  title?: string;
}

interface IModalComposition {
  Footer: typeof ModalFooter;
  Content: typeof ModalContent;
}

// eslint-disable-next-line react/prop-types
const Modal: FC<IModal> & IModalComposition = ({ children, loading = false, className, closeModal, title = '' }) => {
  return (
    <div className={css.container}>
      <div className={`${css.modal} ${className}`}>
        <Loader loading={loading}>
          <div className={css.title}>
            {title}
            <Button
              className={css.closeButton}
              variant='icon'
              iconR={<CloseIcon className={css.closeIcon} />}
              onClick={closeModal}
            />
          </div>
          {children}
        </Loader>
      </div>
    </div>
  );
};

const ModalContent: FC = ({ children }) => <div className={css.content}>{children}</div>;
Modal.Content = ModalContent;

const ModalFooter: FC = ({ children }) => <div className={css.footer}>{children}</div>;
Modal.Footer = ModalFooter;

export default Modal;
