import { WFPFooterIcon } from '@assets/svg';
import css from './styles.module.scss';
import { FC } from 'react';

interface IFooter {
  releaseVersion?: string;
  termsOfUseLink?: string;
  privatePolicyLink?: string;
}

const Footer: FC<IFooter> = ({ releaseVersion, termsOfUseLink, privatePolicyLink }) => {
  return (
    <footer className={css.footer}>
      <a href='http://www1.wfp.org/'>
        <div>
          <span className={css.label}>powered by</span>
          <WFPFooterIcon className={css.icons} />
        </div>
      </a>
      <span>{releaseVersion ? releaseVersion : null}</span>
      <div>
        <span>{`${new Date().getFullYear()} © WFP ${termsOfUseLink || privatePolicyLink ? '| ' : ''}`}</span>
        {termsOfUseLink ? (
          <a href={termsOfUseLink} target='_blank' rel='noreferrer'>
            Terms of Use {privatePolicyLink ? '| ' : ''}
          </a>
        ) : null}
        {privatePolicyLink ? (
          <a href={privatePolicyLink} target='_blank' rel='noreferrer'>
            Privacy Policy
          </a>
        ) : null}
      </div>
    </footer>
  );
};

export default Footer;
