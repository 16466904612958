import React, { FC } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import css from './styles.module.scss';

interface ITab {
  name: string | JSX.Element;
  key: string;
  disabled?: boolean;
}

interface ITabs {
  tabList: ITab[];
  tabKey: string;
  onTabChange: (tab: string) => void;
}

const CustomTabs: FC<ITabs> = ({ tabList, tabKey, onTabChange, children }) => {
  const selectedIndex = tabList.findIndex(({ key }) => key === tabKey);

  return (
    <Tabs
      className={css.tabs}
      defaultFocus
      selectedIndex={selectedIndex}
      onSelect={(index: number) => onTabChange(tabList[index].key)}
    >
      <TabList>
        {tabList.map((tab, idx) => (
          <Tab key={idx} disabled={tab.disabled}>
            {tab.name}
          </Tab>
        ))}
      </TabList>
      {/* Note: Component can be used without TabPanel, just like sort of menu */}
      {!children ? tabList.map((_, idx) => <TabPanel key={idx} />) : children}
    </Tabs>
  );
};

export default CustomTabs;
