import { FC } from 'react';
import { useBanner, MaintenanceBanner } from '@unbooking/ui-kit';
import { Button, Dropdown, Main } from '@components';
import { MechanicPlanIcon, StatementIcon, ViewBookingsIcon, MarkerIcon, AnalyticsIcon } from '@assets/svg';
import { URLS } from '@common/constants';
import { AppointmentsTodayStat, HomeStatData } from '@common/interfaces';
import { useQuery } from 'react-query';
import { useFacilityContext, useRepository } from '@context';
import css from './styles.module.scss';

const EntryPage: FC = () => {
  const { bookingRepository, appointmentRepository } = useRepository();
  const { facilityId, managedFacilities, setFacilityIdOnMonolith } = useFacilityContext();

  const { bannerMessage, closeBanner } = useBanner({
    switchName: 'maintenance_notice_workshops',
    switchesUrl: `${process.env.REACT_APP_HBH_API}/v2/core/feature-flags/`,
    monolithApiKey: String(process.env.REACT_APP_HBH_KEY),
  });

  const { data: stats, isLoading } = useQuery<HomeStatData>(
    ['stats', facilityId],
    () => bookingRepository.getHomeStats(facilityId),
    {
      enabled: Boolean(facilityId),
    }
  );

  const { data: appointmentsToday, isLoading: isAppointmentsTodayLoading } = useQuery<AppointmentsTodayStat>(
    'appointments-today',
    () => appointmentRepository.getAppointmentsForToday(facilityId)
  );

  return (
    <Main loading={isLoading || isAppointmentsTodayLoading} withoutPaddings>
      {bannerMessage ? <MaintenanceBanner message={bannerMessage} onClose={closeBanner} /> : null}
      <div className={css.container}>
        <div className={css.title}>
          Welcome to your <br /> <b>Workshop</b> Digital Office
        </div>
        <div className={css.place}>
          <MarkerIcon />
          {managedFacilities.length > 0 ? (
            <Dropdown
              className={css.dropdown}
              value={managedFacilities.find(({ value }) => value === facilityId)}
              options={managedFacilities}
              onChange={({ value }) => {
                setFacilityIdOnMonolith(value);
                location.reload();
              }}
            />
          ) : null}
        </div>
        <div className={css.blocks}>
          <div className={css.block}>
            <div className={css.label}>Bookings</div>
            <div className={css.description}>Track status of bookings for maintenance.</div>
            <div className={css.bottom}>
              <b>{stats?.to_be_processed}</b> Bookings to be processed
              <Button
                className={css.button}
                iconR={<ViewBookingsIcon />}
                link={URLS.BOOKINGS}
                text='View bookings'
                variant='lochmara'
              />
            </div>
          </div>
          <div className={css.block}>
            <div className={css.label}>Asset handover planner</div>
            <div className={css.description}>Schedule of check-in and check-out of assets.</div>
            <div className={css.bottom}>
              <b>{appointmentsToday?.checkins}</b> Check-in / Inspection planned today
              <br />
              <b>{appointmentsToday?.checkouts}</b> Check-out planned today
              <Button
                className={css.button}
                iconR={<MechanicPlanIcon />}
                link={URLS.PLANNER}
                text='View appointments'
                variant='forest'
              />
            </div>
          </div>
          <div className={css.block}>
            <div className={css.label}>Invoices and statements</div>
            <div className={css.description}>Create and manage invoices or statements for your workshop customers.</div>
            <div className={css.bottom}>
              <b>{stats?.to_invoice}</b> Jobcards to be invoiced
              <Button
                className={css.button}
                iconR={<StatementIcon />}
                link={URLS.DOCUMENTS}
                text='Manage invoices'
                variant='kumera'
              />
            </div>
          </div>
          <div className={css.block}>
            <div className={css.label}>Analytics</div>
            <div className={css.description}>Access your workshop KPIs.</div>
            <div className={css.bottom}>
              <Button
                className={css.button}
                iconR={<AnalyticsIcon />}
                link={URLS.ANALYTICS}
                text='View analytics'
                variant='paarl'
              />
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default EntryPage;
