import React, { FC } from 'react';
import css from './styles.module.scss';

export interface IText {
  disabled?: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
}

const Text: FC<IText> = ({ disabled, onChange, placeholder, value }) => {
  return (
    <input
      disabled={disabled}
      placeholder={placeholder}
      className={css.input}
      value={value}
      type='text'
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default Text;
