import React, { FC } from 'react';
import { CompletedIcon } from '@assets/svg';
import './styles.scss';

interface INavigationElementConfigItem {
  label: string;
  icon: JSX.Element;
  isCompleted: boolean;
  isDisabled: boolean;
  onClick?: (key: number) => void;
}

interface INavigationStepperProps {
  config: INavigationElementConfigItem[];
  currentStep: number;
  disableIfBeforeCurrentStep?: boolean;
  completedIfAfterCurrentStep?: boolean;
}

interface iNavigationElementProps {
  icon: JSX.Element;
  label: string;
  key: number;
  isDisabled?: boolean;
  isActive?: boolean;
  isCompleted?: boolean;
  onClick?: () => void;
}

const NavigationElement: FC<iNavigationElementProps> = ({
  icon,
  label,
  isDisabled,
  isActive,
  isCompleted,
  onClick,
}) => {
  return (
    <div className={`navigation-element ${isActive ? 'active' : ''}`}>
      <span className={`navigation-icon ${isActive ? 'active' : 'disabled'} ${isCompleted ? 'completed' : ''}`}>
        {!isCompleted ? icon : <CompletedIcon className='completed-icon' />}
      </span>
      <span
        onClick={!isDisabled && onClick ? onClick : () => null}
        className={`navigation-label ${isDisabled ? 'disabled' : ''} ${onClick && !isDisabled ? 'clickable' : ''}`}
      >
        {label}
      </span>
    </div>
  );
};

const NavigationStepper: FC<INavigationStepperProps> = ({
  config,
  currentStep,
  disableIfBeforeCurrentStep = true,
  completedIfAfterCurrentStep = true,
}) => {
  return (
    <div className='navigation-stepper-container'>
      <div className='navigation-content'>
        {config.map((navigationData, key) => (
          <NavigationElement
            key={key}
            isDisabled={navigationData.isDisabled || (disableIfBeforeCurrentStep && currentStep < key)}
            label={navigationData.label}
            isCompleted={navigationData.isCompleted || (completedIfAfterCurrentStep && currentStep > key)}
            onClick={navigationData.onClick !== undefined ? () => navigationData.onClick?.(key) : undefined}
            isActive={currentStep >= key}
            icon={navigationData.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default NavigationStepper;
