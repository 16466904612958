import React, { FC } from 'react';
import css from './styles.module.scss';
import { EmptyIcon } from '@assets/svg';

interface IEmpty {
  text?: string;
}

const Empty: FC<IEmpty> = ({ text = 'No data' }) => {
  return (
    <div className={css.empty}>
      <EmptyIcon />
      <div>{text}</div>
    </div>
  );
};

export default Empty;
