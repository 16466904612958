import React, { FC } from 'react';
import css from './styles.module.scss';
import { useField } from 'informed';
import { InformedFieldState } from '@common/interfaces';

interface ITextAreaField {
  disabled?: boolean;
  name: string;
}

const TextAreaField: FC<ITextAreaField> = ({ disabled, name }) => {
  const { fieldApi, fieldState, ref, render } = useField({ name });

  const { value, error, showError } = fieldState as InformedFieldState<string>;

  const { setValue, setTouched } = fieldApi;

  return render(
    <div className={css.field}>
      <textarea
        className={`${css.input} ${showError ? css.inputError : ''}`}
        disabled={disabled}
        ref={ref}
        value={!value ? '' : value}
        onChange={(e) => setValue(e.target.value, e)}
        onBlur={(e) => setTouched(true, e)}
      />
      {showError ? <div className={css.error}>{error}</div> : null}
    </div>
  );
};

export default TextAreaField;
