import React, { FC } from 'react';
import { Button, InformedField, Modal, TextField } from '@components';
import { ArrowRightIcon } from '@assets/svg';
import { Form, FormState } from 'informed';
import { useFacilityContext, useRepository } from '@context';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { IBookingDetails, JobcardLinkingData } from '@common/interfaces';
import css from './styles.module.scss';

interface ISyncJobcardModal {
  bookingDetails: IBookingDetails;
  closeModal: () => void;
  refetch: () => void;
}

interface FormValues {
  job_number: string;
}

const yupSchema = Yup.object().shape({
  job_number: Yup.string().required('Required'),
});

const SyncJobcardModal: FC<ISyncJobcardModal> = ({ bookingDetails, closeModal, refetch }) => {
  const { facilityId } = useFacilityContext();
  const { bookingRepository } = useRepository();

  const { uuid: bookingId } = bookingDetails;

  const { mutate: linkJobcard } = useMutation(
    (data: JobcardLinkingData) => bookingRepository.linkJobcard(facilityId, bookingId, data),
    {
      onSuccess: () => {
        toast.success('Jobcard linking was started. Please wait.');
        closeModal();
        refetch();
      },
    }
  );

  const onSubmit = ({ values }: FormState) => {
    const { job_number } = values as unknown as FormValues;
    linkJobcard({ job_number });
  };

  const initialValues = {
    job_number: undefined,
  };

  return (
    <Modal className={css.modal} title='Select FMS jobcard number to synchronize data' closeModal={closeModal}>
      <Form onSubmit={onSubmit} yupSchema={yupSchema} initialValues={initialValues}>
        <Modal.Content>
          <div className={css.note}>
            Enter the jobcard number. All the data will be synchronized, any previous linked jobcard will be removed.
          </div>
          <InformedField label='FMS Jobcard *'>
            <TextField name='job_number' />
          </InformedField>
        </Modal.Content>
        <Modal.Footer>
          <Button text='Cancel' variant='transparent-negative' onClick={closeModal} />
          <Button text='Synchronize' iconR={<ArrowRightIcon />} type='submit' />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SyncJobcardModal;
