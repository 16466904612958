import React, { FC } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.module.scss';

const Toast: FC = () => {
  return (
    <ToastContainer
      position='top-center'
      autoClose={3000}
      closeButton={false}
      pauseOnHover={false}
      pauseOnFocusLoss={false}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      transition={Zoom}
      limit={5}
    />
  );
};

export default Toast;
