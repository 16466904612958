import { parse } from 'date-fns';

// Note: convert 2024-04-10T20:00:00Z to TODAY 20:00:00 GMT+0300

const getLocalTime = (isoDate: string) => {
  const [, time] = isoDate.slice(0, -1).split('T');
  return parse(time, 'HH:mm:ss', new Date());
};

export default getLocalTime;
