import { FC, useState } from 'react';
import { IBookingDetails, JobcardStatus, JobcardSyncData } from '@common/interfaces';
import { formatDistanceToNow } from 'date-fns';
import Box from '../Box';
import { JobcardIcon, SyncIcon } from '@assets/svg';
import { Button, Empty, StatusLabel } from '@components';
import { formatToMoneyString } from '@common/utils';
import SyncJobcardModal from '../SyncJobcardModal';
import { useMutation } from 'react-query';
import { useFacilityContext, useRepository } from '@context';
import { toast } from 'react-toastify';
import css from './styles.module.scss';

interface IJobCardBlock {
  bookingDetails: IBookingDetails;
  refetch: () => void;
}

const getStatusColor = (job_status: string) => {
  let color = '';

  switch (job_status) {
    case JobcardStatus.WAITING_FOR_PARTS:
      color = '#ffbb4d';
      break;
    case JobcardStatus.WORK_IN_PROGRESS:
      color = '#26d85d';
      break;
    case JobcardStatus.CLOSED:
      color = '#868686';
      break;
    default:
      color = '';
      break;
  }

  return color;
};

const JobCardBlock: FC<IJobCardBlock> = ({ bookingDetails, refetch }) => {
  const { jobcardRepository } = useRepository();
  const { facilityId } = useFacilityContext();

  const { jobcard, fms_sync_in_progress, fms_request_sent, fms_jobcard_link_request_sent } = bookingDetails;

  const [syncJobcardModal, setSyncJobcardModal] = useState(false);

  const { mutate: syncJobcardsData, isLoading } = useMutation(
    (params: JobcardSyncData) => jobcardRepository.syncJobcardsData(facilityId, params),
    {
      onSuccess: ({ detail }) => {
        toast.success(detail);
        refetch();
      },
    }
  );

  let emptyContent = '';

  switch (fms_request_sent) {
    case null:
      emptyContent = 'Job card not created yet';
      break;
    case true:
      emptyContent = 'Creation in progress, come back later';
      break;
    case false:
      emptyContent = 'Creation was aborted due to an error. Please contact support';
      break;
    default:
      emptyContent = '';
      break;
  }

  let status = null;

  if (!jobcard && fms_jobcard_link_request_sent === true) {
    status = <StatusLabel text='Jobcard linking in progress' variant='orange' />;
  } else if (!jobcard && fms_jobcard_link_request_sent === false) {
    status = <StatusLabel text='Jobcard linking failed - try again' variant='red' />;
  } else if (fms_sync_in_progress === true) {
    status = <StatusLabel text='Jobcard sync in progress' variant='orange' />;
  } else if (fms_sync_in_progress === false) {
    status = <StatusLabel text='Jobcard sync completed' variant='green' />;
  }

  const buttons = jobcard ? (
    <>
      {fms_sync_in_progress !== true ? (
        <Button
          text='Sync with FMS'
          iconR={<SyncIcon />}
          variant='text'
          className={css.syncButton}
          disabled={isLoading}
          onClick={() => syncJobcardsData({ job_number: jobcard.job_number })}
        />
      ) : null}
      {/* Note: BE for editing in progress */}
      {/* <Button text='Edit' iconR={<EditIcon />} variant='text' onClick={() => setSyncJobcardModal(true)} /> */}
    </>
  ) : null;

  return (
    <Box title='Jobcard details' icon={<JobcardIcon />} status={status} buttons={buttons}>
      <div className={css.jobCard}>
        {jobcard ? (
          <>
            <div className={css.jobCardTime}>
              {`Jobcard updated ${
                jobcard.latest_sync_time
                  ? formatDistanceToNow(new Date(jobcard.latest_sync_time), {
                      addSuffix: true,
                    })
                  : ''
              }`}
            </div>
            <div className={css.jobCardRow}>
              <div className={css.jobCardLabel}>Number</div>
              <div className={css.jobCardValue}>{jobcard.job_number}</div>
            </div>
            <div className={css.jobCardRow}>
              <div className={css.jobCardLabel}>Total cost</div>
              <div className={css.jobCardValue}>{formatToMoneyString(jobcard.cost_total)}</div>
            </div>
            <div className={css.jobCardRow}>
              <div className={css.jobCardLabel}>Status</div>
              <div className={css.jobCardValue}>
                <div className={css.colorDot} style={{ backgroundColor: getStatusColor(jobcard.job_status) }}></div>
                {jobcard.job_status}
              </div>
            </div>
          </>
        ) : (
          <>
            <Empty text={emptyContent} />
            {!fms_jobcard_link_request_sent ? (
              <div className={css.syncBlock}>
                Jobcard already existing for this booking?{' '}
                <Button
                  text='Enter jobcard number here'
                  onClick={() => setSyncJobcardModal(true)}
                  variant='text'
                  className={css.button}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
      {syncJobcardModal ? (
        <SyncJobcardModal
          closeModal={() => setSyncJobcardModal(false)}
          bookingDetails={bookingDetails}
          refetch={refetch}
        />
      ) : null}
    </Box>
  );
};

export default JobCardBlock;
