import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import css from './styles.module.scss';

export type ButtonColor =
  | 'lochmara'
  | 'supernova'
  | 'lynch'
  | 'forest'
  | 'white'
  | 'kumera'
  | 'alizarin'
  | 'space'
  | 'paarl';

export type ButtonVariant = ButtonColor | 'transparent' | 'transparent-negative' | 'icon' | 'text';

interface IButton {
  className?: string;
  disabled?: boolean;
  iconL?: JSX.Element;
  iconR?: JSX.Element;
  link?: string;
  onClick?: (e: Event) => void;
  target?: string;
  text?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
}

const Button: FC<IButton> = ({
  className,
  disabled,
  iconL,
  iconR,
  link,
  onClick,
  text = '',
  type = 'button',
  variant = 'lochmara',
  ...props
}) => {
  const handleClick = useCallback((e) => onClick && onClick(e), [onClick]);

  const classNamesList = `${css.button} ${css[`btn-${variant}`]} ${disabled ? css.disabled : 'disabled'} ${className}`;

  const content = (
    <>
      {iconL}
      {text && <span>{text}</span>}
      {iconR}
    </>
  );

  const isExternalLink = link?.startsWith('https://') || link?.startsWith('mailto:');

  return (
    <>
      {isExternalLink ? (
        <a className={classNamesList} href={link} {...props}>
          {content}
        </a>
      ) : link ? (
        <Link className={classNamesList} to={link} {...props}>
          {content}
        </Link>
      ) : (
        <button className={classNamesList} onClick={handleClick} type={type} disabled={disabled} {...props}>
          {content}
        </button>
      )}
    </>
  );
};

export default Button;
