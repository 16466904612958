import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { formatToMoneyString } from '@common/utils';
import { CloseIcon, EditIcon, SaveIcon } from '@assets/svg';
import { Button, Tip, Switcher } from '@components';
import css from './styles.module.scss';

interface ITotalCostsBox {
  mcrMarginValue: number;
  setWorkshopMarginValue: Dispatch<SetStateAction<number>>;
  totalLineItems: number;
  workshopMarginValue: number;
  mcrMarginIncluded: boolean;
  setMcrMarginIncluded: Dispatch<SetStateAction<boolean>>;
}

const TotalCostsBox: FC<ITotalCostsBox> = ({
  mcrMarginValue,
  setWorkshopMarginValue,
  totalLineItems,
  workshopMarginValue,
  mcrMarginIncluded,
  setMcrMarginIncluded,
}) => {
  const [marginEditable, setMarginEditable] = useState(false);
  const [marginEditableValue, setMarginEditableValue] = useState(workshopMarginValue);

  const workshopMargin = totalLineItems * (workshopMarginValue / 100);
  const mcrMargin = (totalLineItems + workshopMargin) * (mcrMarginValue / 100);
  const totalCost = totalLineItems + workshopMargin + (mcrMarginIncluded ? mcrMargin : 0);

  return (
    <div className={css.totals}>
      <div className={css.row}>
        <div>Total line items</div>
        <div>{formatToMoneyString(totalLineItems)}</div>
      </div>
      <div className={css.row}>
        <div className={css.formRow}>
          Workshop facility charges ({workshopMarginValue}%)
          {marginEditable ? (
            <>
              <input
                value={marginEditableValue}
                type='number'
                className={css.percentageField}
                onChange={(e) => setMarginEditableValue(e.target.value as unknown as number)}
              />
              <Tip text='Margin should be positive number' isVisible={marginEditableValue < 0}>
                <Button
                  iconL={<SaveIcon className={css.icon} />}
                  onClick={() => {
                    setWorkshopMarginValue(marginEditableValue);
                    setMarginEditable(false);
                  }}
                  variant='icon'
                  disabled={marginEditableValue < 0}
                />
              </Tip>
              <Button
                variant='text'
                iconL={<CloseIcon />}
                onClick={() => {
                  setMarginEditable(false);
                  setMarginEditableValue(workshopMarginValue);
                }}
              />
            </>
          ) : (
            <Button iconL={<EditIcon className={css.icon} />} onClick={() => setMarginEditable(true)} variant='icon' />
          )}
        </div>
        <div>{formatToMoneyString(workshopMargin)}</div>
      </div>
      <div className={`${css.row} ${!mcrMarginIncluded ? css.disabled : ''}`}>
        <div className={css.formRow}>
          MCR ({mcrMarginValue}%) included <Switcher checked={mcrMarginIncluded} onChange={setMcrMarginIncluded} />
        </div>
        <div>{formatToMoneyString(mcrMargin)}</div>
      </div>
      <div className={css.row}>
        <div>Total cost</div>
        <div>{formatToMoneyString(totalCost)}</div>
      </div>
    </div>
  );
};

export default TotalCostsBox;
