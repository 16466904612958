import React, { FC } from 'react';
import { Button, Modal } from '@components';
import { SuccessIcon } from '@assets/svg';
import { IBookingDetailsPostData, IBookingStatus } from '@common/interfaces';

interface IApprovingModal {
  closeModal: () => void;
  updateBooking: (data: IBookingDetailsPostData) => void;
}

const ApprovingModal: FC<IApprovingModal> = ({ closeModal, updateBooking }) => {
  return (
    <Modal title='Booking Approval' closeModal={closeModal}>
      <Modal.Content>Do you want to approve this booking?</Modal.Content>
      <Modal.Footer>
        <Button text='Cancel' variant='transparent-negative' onClick={closeModal} />
        <Button
          text='Approve'
          iconR={<SuccessIcon />}
          variant='forest'
          onClick={() => {
            closeModal();
            updateBooking({ state: IBookingStatus.ACCEPTED });
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ApprovingModal;
