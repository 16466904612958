import { useField } from 'informed';
import React, { FC, KeyboardEvent } from 'react';
import { InformedFieldState } from '@common/interfaces';
import css from './styles.module.scss';
import { formatToMoneyString } from '@common/utils';

export interface INumberField {
  name: string;
  disabled?: boolean;
  skipFormatting?: boolean;
  maxLength?: number;
  allowDecimal?: boolean;
}

const NumberField: FC<INumberField> = ({ name, disabled, skipFormatting = false, maxLength, allowDecimal = false }) => {
  const { fieldState, fieldApi, render, ref } = useField({ name });

  const { value, error, showError } = fieldState as InformedFieldState<string>;

  const { setValue, setTouched } = fieldApi;

  const validate = (e: KeyboardEvent<HTMLInputElement>, maxValue = 12) => {
    const length = (e.target as HTMLInputElement).value.length;
    const isMaxLength = length >= maxValue;
    const isAllowedKeys =
      (allowDecimal && e.key === '.') ||
      e.key === 'Backspace' ||
      e.key === 'Tab' ||
      (e.shiftKey && e.key === 'Tab') ||
      (e.ctrlKey && e.key === 'v') ||
      (e.ctrlKey && e.key === 'c') ||
      (e.ctrlKey && e.key === 'a');
    const isNoDigit = !/^\d$/.test(e.key);

    if ((isNoDigit && !isAllowedKeys) || isMaxLength) {
      if (!isAllowedKeys) e.preventDefault();
    }
  };

  const format = (value: string) => {
    if (!value) return value;

    if (allowDecimal) {
      return formatToMoneyString(value);
    } else {
      return new Intl.NumberFormat('en-US').format(parseFloat(value.replace(/,/g, ''))).trim();
    }
  };

  return render(
    <div className={css.field}>
      <input
        ref={ref}
        value={value ?? ''}
        type='text'
        className={`${css.input} ${showError ? css.inputError : ''}`}
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => {
          setTouched(true);
          if (!skipFormatting) setValue(format(e.target.value));
        }}
        disabled={disabled}
        onKeyDown={(e) => validate(e, maxLength)}
      />
      {showError ? <div className={css.error}>{error}</div> : null}
    </div>
  );
};

export default NumberField;
