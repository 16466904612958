import React, { FC } from 'react';
import { getLocalDate } from '@common/utils';
import { differenceInMinutes, format } from 'date-fns';
import { CarIcon, EditIcon, GeneratorIcon, TruckIcon } from '@assets/svg';
import { IAppointment, IAppointmentType, ITypeOfAsset, Nullable } from '@common/interfaces';
import { Tip } from '@components';
import { ChevronIcon } from '@assets/icons';
import { LINKS } from '@common/constants';
import css from './styles.module.scss';

export interface IEvent {
  booking_id: string;
  duration: number;
  end: string;
  jobcard_job_number: Nullable<string>;
  make: string;
  model: string;
  plate_number: string;
  requesting_agency: string;
  start: string;
  type: IAppointmentType;
  type_of_asset: string;
  uuid: string;
  assign_to: Nullable<string>;
}

export const mapAppointments = (appointments?: IAppointment[]): IEvent[] => {
  if (!appointments) return [];
  return appointments.map(
    ({ uuid, appointment_type, assign_to, from_to_datetime_range, jobcard_job_number, booking, make, model }) => {
      const { from, to } = from_to_datetime_range;
      const { plate_number, requesting_agency, type_of_asset, uuid: booking_id } = booking;

      return {
        booking_id,
        duration: differenceInMinutes(new Date(to), new Date(from)),
        end: to,
        jobcard_job_number,
        make,
        model,
        plate_number,
        requesting_agency,
        start: from,
        type: appointment_type,
        type_of_asset,
        uuid,
        assign_to: assign_to?.uuid || null,
      };
    }
  );
};

const MIN_BLOCK_DURATION = 60; // min. If we have short duration and little box - we show info icon
const EXTRA_MIN_BLOCK_DURATION = 30; // min. If we have extra little box - show just dots

const EventBlock: FC = (appointment) => {
  const {
    make,
    model,
    plate_number,
    jobcard_job_number,
    duration,
    requesting_agency,
    start,
    type,
    type_of_asset,
    booking_id,
  } = appointment as IEvent;

  let icon = null;
  switch (type_of_asset) {
    case ITypeOfAsset.TRUCK:
      icon = <TruckIcon />;
      break;
    case ITypeOfAsset.GENERATOR:
      icon = <GeneratorIcon />;
      break;
    default:
      icon = <CarIcon />;
      break;
  }

  const bookingPlannerLink =
    type === IAppointmentType.CHECK_IN ? LINKS.plannerCheckIn(booking_id) : LINKS.plannerCheckOut(booking_id);

  const tipContent = (
    <div className={css.content}>
      <div className={css.time}>
        {format(getLocalDate(start), 'HH:mm')}
        {' - '}
        <a href={bookingPlannerLink} className={css.link}>
          <EditIcon />
          Edit time
        </a>
      </div>
      <a href={LINKS.booking(booking_id)} className={css.link}>
        View booking
      </a>
      <div className={css.label}>
        {icon}
        <div className={css.vehicle}>
          {make} {model}, {plate_number}
        </div>
      </div>
      <div className={css.info}>{type === IAppointmentType.CHECK_OUT ? jobcard_job_number : requesting_agency}</div>
    </div>
  );

  return (
    <div data-testid='event' className={`${css.container} ${type === IAppointmentType.CHECK_IN ? css.in : css.out}`}>
      <Tip content={tipContent} className={css.tooltip}>
        {duration < EXTRA_MIN_BLOCK_DURATION ? (
          <div className={css.extraSmallBox}>
            <ChevronIcon type='bottom' />
          </div>
        ) : duration < MIN_BLOCK_DURATION ? (
          <div className={css.smallBox}>
            {icon}
            <div className={css.vehicle}>
              {make} {model}, {plate_number}
            </div>
          </div>
        ) : (
          <div className={css.box}>
            <div className={css.content}>
              <div>{format(getLocalDate(start), 'HH:mm')}</div>
              <div className={css.label}>
                {icon}
                <div className={css.vehicle}>
                  {make} {model}, {plate_number}
                </div>
              </div>
              <div className={css.info}>
                {type === IAppointmentType.CHECK_OUT ? jobcard_job_number : requesting_agency}
              </div>
            </div>
          </div>
        )}
      </Tip>
    </div>
  );
};

export default EventBlock;
