import React, { FC } from 'react';
import comCss from '../../styles.module.scss';

interface InformedFieldProps {
  children: JSX.Element | Array<JSX.Element>;
  description?: string;
  label: string;
  className?: string;
}

const InformedField: FC<InformedFieldProps> = ({ children, description, label, className = '' }) => {
  return (
    <div className={`${comCss.field} ${className}`}>
      <div className={comCss.label}>{label}</div>
      {description ? <div className={comCss.description}>{description}</div> : null}
      {children}
    </div>
  );
};

export default InformedField;
