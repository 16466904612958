import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '@unbooking/ui-auth';
import App from './App';
import { authConfig, axios } from '@common/utils';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
// Note: it makes conflict with current styles
// import '../node_modules/@unbooking/ui-kit/style.css';
import './assets/styles/index.scss';

const ENV = process.env.REACT_APP_ENVIRONMENT || '';
const ENABLE_SENTRY = ['production', 'qa', 'develop'].includes(ENV);
if (ENABLE_SENTRY) {
  Sentry.init({
    dsn: 'https://f15f9031737a4ae1d2854049f56a931c@o274918.ingest.us.sentry.io/4507349866119168',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 0.001,
    environment: ENV,
  });
}

const container = document.getElementById('root');
// Note: according to https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AuthProvider authConfig={authConfig} axiosInstance={axios}>
      <Router>
        <App />
      </Router>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
