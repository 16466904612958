// Note: format number/string 44423.33 to string '44,423.33'
// Note: return n.d. for null, undefined; return 0,00 for 0

const formatToMoneyString = (value?: string | number | null) => {
  if (!value && value !== 0) return 'n.d.';

  const tempValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    .format(tempValue)
    .replace(/\$/g, '')
    .trim();
};

export default formatToMoneyString;
