import { FC, useEffect, useState } from 'react';
import {
  BarChart,
  BarStackedChart,
  Button,
  CheckboxDropdownFull,
  DoughnutChart,
  Empty,
  LineChart,
  Main,
  MonthPicker,
  Page,
} from '@components';
import { BookingAnalytics, BookingFormOptions, DateRange, IDropdownOption, Nullable } from '@common/interfaces';
import { useQuery } from 'react-query';
import { useFacilityContext, useRepository } from '@context';
import { DEFAULT_SERVER_DATE_FORMAT } from '@common/constants';
import { format } from 'date-fns';
import css from './styles.module.scss';

const formatNumber = (num: Nullable<number>): string => {
  if (!num) return 'n.d.';

  if (num < 1000) return num.toFixed(0);

  const units = ['', 'k', 'M', 'B', 'T'];
  let unitIndex = 0;

  while (num >= 1000 && unitIndex < units.length - 1) {
    num /= 1000;
    unitIndex++;
  }

  return num.toFixed(1) + units[unitIndex];
};

const AnalyticsPage: FC = () => {
  const { facilityId, managedFacilities } = useFacilityContext();
  const { bookingRepository } = useRepository();

  const [dateFilter, setDateFilter] = useState<Nullable<DateRange>>(null);
  const [workshopFilter, setWorkshopFilter] = useState<IDropdownOption[]>(managedFacilities);
  const [typeFilter, setTypeFilter] = useState<IDropdownOption[]>([]);

  const { data: options, isLoading: isOptionsLoading } = useQuery<BookingFormOptions>(
    ['options', dateFilter, workshopFilter],
    () =>
      bookingRepository.getAnalyticsOptions(facilityId, {
        created_after: dateFilter?.created_after,
        created_before: dateFilter?.created_before,
        facilities: workshopFilter.map(({ value }) => value).join(','),
      }),
    {
      enabled: workshopFilter.length > 0,
    }
  );

  const typeOptions = options?.type_of_asset || [];

  const { data: analytics, isLoading: isDataLoading } = useQuery<BookingAnalytics>(
    ['analytics', dateFilter, workshopFilter, typeFilter],
    () =>
      bookingRepository.getAnalytics(facilityId, {
        created_after: dateFilter?.created_after,
        created_before: dateFilter?.created_before,
        facilities: workshopFilter.map(({ value }) => value).join(','),
        type_of_asset: typeFilter.map(({ value }) => value).join(','),
      }),
    {
      enabled: workshopFilter.length > 0 && typeFilter.length > 0,
    }
  );

  const clearAllParams = () => {
    setWorkshopFilter(managedFacilities);
    setTypeFilter(typeOptions);
    setDateFilter(null);
  };

  // Asset immobilized > Asset under maintenance
  const averageDaysData = analytics
    ? [
        {
          // Asset immobilized at workshop = check out - check in
          label: analytics.avg_days_at_workshop[1].label,
          backgroundColor: '#F1DEE5',
          data: analytics.avg_days_at_workshop[1].data,
        },
        {
          // Asset under maintenance = jobcard job end date - jobcard job start date
          label: analytics.avg_days_at_workshop[0].label,
          backgroundColor: '#BE367E',
          data: analytics.avg_days_at_workshop[0].data,
        },
      ]
    : [];

  const isFiltered =
    dateFilter || workshopFilter.length !== managedFacilities.length || typeFilter.length !== typeOptions.length;

  useEffect(() => {
    setWorkshopFilter(managedFacilities);
  }, [managedFacilities]);

  useEffect(() => {
    setTypeFilter(typeOptions);
  }, [typeOptions.length]);

  return (
    <Main loading={isDataLoading || isOptionsLoading}>
      <Page>
        <div className={css.title}>Workshop Analytics</div>
        <div className={css.filters}>
          <MonthPicker
            triggerText='Any'
            triggerStyle='box'
            showDates
            label='Analytics date interval'
            initialFrom={dateFilter?.created_after ? new Date(dateFilter.created_after) : null}
            initialTo={dateFilter?.created_before ? new Date(dateFilter.created_before) : null}
            setMonths={(from, to) => {
              if (from && to) {
                setDateFilter({
                  created_after: format(from, DEFAULT_SERVER_DATE_FORMAT),
                  created_before: format(to, DEFAULT_SERVER_DATE_FORMAT),
                });
              }
            }}
          />
          <CheckboxDropdownFull
            items={managedFacilities}
            onChange={setWorkshopFilter}
            selectedItems={workshopFilter}
            label='Workshop'
            placeholder='Not selected'
          />
          <CheckboxDropdownFull
            items={typeOptions}
            onChange={setTypeFilter}
            selectedItems={typeFilter}
            label='Asset type'
            placeholder='Not selected'
          />
          {isFiltered ? (
            <Button className={css.resetButton} text='Reset filters' variant='text' onClick={() => clearAllParams()} />
          ) : null}
        </div>
        {analytics ? (
          <div className={css.content}>
            <div className={css.stat}>
              <div className={css.statInfoBlock}>
                <div className={css.subtitle}>Booking overview</div>
                Overview of demand and request for Workshop activities
              </div>
              <div className={css.statBlock}>
                <div>Bookings</div>
                <div className={css.count}>{formatNumber(analytics.total)}</div>
              </div>
              <div className={css.statBlock}>
                <div>Total labour cost</div>
                <div className={css.count}>{formatNumber(analytics.total_labour_cost)} USD</div>
              </div>
              <div className={css.statBlock}>
                <div>Total parts cost</div>
                <div className={css.count}>{formatNumber(analytics.total_parts_cost)} USD</div>
              </div>
              <div className={css.statBlock}>
                <div>Total MCR cost</div>
                <div className={css.count}>{formatNumber(analytics.total_mcr_cost)} USD</div>
              </div>
              <div className={css.statBlock}>
                <div>Active jobcards</div>
                <div className={css.count}>{formatNumber(analytics.active_jobcards)}</div>
              </div>
            </div>
            <LineChart data={analytics.requests_by_partner_type} title='Requests by Partner type' />
            <div className={css.widgets}>
              <div className={css.block}>
                <DoughnutChart title='Requests by reason type' data={analytics.requests_by_reason_type} />
              </div>
              <div className={css.block}>
                <DoughnutChart title='Requests by asset type' data={analytics.requests_by_asset_type} />
              </div>
              <div className={css.block}>
                <BarChart
                  title='Average request processing days by status'
                  data={analytics.avg_request_processing_days_by_status}
                />
              </div>
              <div className={css.block}>
                <BarStackedChart title='Average days at workshop by asset type' data={averageDaysData} />
              </div>
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </Page>
    </Main>
  );
};

export default AnalyticsPage;
