import React from 'react';
import './styles.scss';
import { Loader } from '@components';

const CallbackPage: React.FC = () => {
  return (
    <section className='login-container'>
      <div className='login-panel callback-panel'>
        <Loader loading full></Loader>
      </div>
    </section>
  );
};

export default CallbackPage;
