import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { SuccessIcon } from '@assets/svg';
import { Button, Main } from '@components';
import { URLS } from '@common/constants';
import css from './styles.module.scss';

interface LocationState {
  workshop?: string;
  number?: string;
}

const BookingSuccessPage: FC = () => {
  const location = useLocation();
  const data = location.state as LocationState;

  return (
    <Main withoutPaddings>
      <div className={css.wrapper}>
        <div className={css.box}>
          <SuccessIcon className={css.icon} />
          <div className={css.title}>
            Your booking <b>{data?.number}</b> has been received!
          </div>
          <div className={css.text}>What will happen next?</div>
          <ol className={css.list}>
            <li>
              <b>We will review the details</b>, assess the availability of our staff and get back to you.
            </li>
            <li>
              <b>In booking is accepted</b> you will receive information for the date and time to bring the asset to
              <b> {data?.workshop || 'workshop'}</b>.
            </li>
            <li>We will perform the needed work and inform when vehicle will be ready for pick-up.</li>
          </ol>
          <Button variant='white' text='Back to homepage' className={css.button} link={URLS.ROOT} />
        </div>
      </div>
    </Main>
  );
};

export default BookingSuccessPage;
