import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, InformedField, Main, TextField } from '@components';
import * as Yup from 'yup';
import { Form, FormState } from 'informed';
import { LoginIcon, LogoIcon } from '@assets/svg';
import { useAuth } from '@unbooking/ui-auth';
import { useGetSsoAllowedDomains, usePostLogin, usePostVerifyB2cUser, useUserType } from '@common/hooks';
import { UserType } from '@common/interfaces';
import { setupNonSSOSession } from '@common/utils';
import css from './styles.module.scss';

interface FormValues {
  email: string;
}

interface FormFullValues {
  email: string;
  password: string;
}

const yupSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
});

const yupFullSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

const LoginPage: FC = () => {
  const { authorize: loginSSO } = useAuth();
  const { data: allowedDomains } = useGetSsoAllowedDomains();
  const { mutateAsync: loginNonSSO } = usePostLogin();
  const { mutateAsync: verifyB2cUser } = usePostVerifyB2cUser();

  const [isPreLogin, setIsPreLogin] = useState(true);
  const { userType, setUserType } = useUserType();

  const isEmailAllowed = useCallback(
    (email: string) => {
      const emailDomain = email.split('@')[1];
      const emailAllowed = allowedDomains?.includes(emailDomain);
      const newUserType = emailAllowed ? UserType.SSO : UserType.NON_FEDERATED;

      return { emailAllowed, newUserType };
    },
    [allowedDomains]
  );

  const onSubmit = useCallback(
    async (formData: FormState) => {
      const formValues = formData.values as unknown as FormValues;

      const { is_b2c_user: isB2CUser } = await verifyB2cUser(formValues.email);
      const authType = isB2CUser ? 'b2c' : 'azure';

      if (isB2CUser) {
        loginSSO?.(authType, formValues.email);
      } else if (isPreLogin) {
        const { emailAllowed, newUserType } = isEmailAllowed(formValues.email);

        setUserType(newUserType);
        emailAllowed ? loginSSO?.(authType) : setIsPreLogin(false);
      } else if (userType === UserType.NON_FEDERATED) {
        const response = await loginNonSSO(formValues as FormFullValues);
        setupNonSSOSession(formValues.email, response.key);
      } else {
        loginSSO?.(authType);
      }
    },
    [loginSSO, loginNonSSO, userType, allowedDomains, isPreLogin]
  );

  const onEmailChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      if (!isPreLogin) {
        const { emailAllowed, newUserType } = isEmailAllowed(event.target.value);

        setUserType(newUserType);
        emailAllowed && setIsPreLogin(true);
      }
    },
    [verifyB2cUser, isEmailAllowed, isPreLogin, setUserType, setIsPreLogin]
  );

  const passwordRequired = useMemo(() => !isPreLogin && userType === UserType.NON_FEDERATED, [isPreLogin, userType]);

  const schema = passwordRequired ? yupFullSchema : yupSchema;

  const defaultValues = useMemo(
    () => (passwordRequired ? { email: '', password: '' } : { email: '' }),
    [passwordRequired]
  );

  useEffect(() => {
    if (userType === UserType.SSO) {
      loginSSO?.();
    }
  }, []);

  return (
    <Main loading={false} withoutPaddings background='home' className={css.container}>
      <div className={css.form}>
        <div className={css.title}>
          <LogoIcon />
          <div>
            WORKSHOPS <br />
            <span>SERVICES</span>
          </div>
        </div>
        <div className={css.subtitle}>
          Log in to <span>Workshop</span>
        </div>
        <Form onSubmit={onSubmit} yupSchema={schema} initialValues={defaultValues}>
          <InformedField label='Work email *' className={css.row}>
            <TextField name='email' placeholder='name@example.com' onChange={onEmailChange} />
          </InformedField>
          {passwordRequired ? (
            <InformedField label='Password *' className={css.row}>
              <TextField name='password' type='password' />
            </InformedField>
          ) : null}
          <div className={css.registration}>
            New user? <a href={`${process.env.REACT_APP_HBH_URL}/register/`}>Create your account</a>
          </div>
          <Button variant='supernova' text='Login' iconR={<LoginIcon />} type='submit' className={css.button} />
        </Form>
      </div>
    </Main>
  );
};

export default LoginPage;
