import React, { FC } from 'react';
import comCss from '../../styles.module.scss';

interface SubtitleProps {
  icon: JSX.Element;
  text: string;
}

const Subtitle: FC<SubtitleProps> = ({ text, icon }: SubtitleProps) => {
  return (
    <div className={comCss.subtitle}>
      <div className={comCss.subtitleIcon}>{icon}</div>
      {text}
    </div>
  );
};

export default Subtitle;
