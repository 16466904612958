/* eslint-disable indent */
import React, { FC } from 'react';

interface IChevronIcon {
  color?: string;
  width?: string | number;
  height?: string | number;
  type: 'top' | 'bottom' | 'right' | 'left';
  className?: string;
}

const ChevronIcon: FC<IChevronIcon> = ({ color, width, height = 20, type, className }) => {
  let transform;

  switch (type) {
    case 'top':
      transform = 'none';
      break;
    case 'bottom':
      transform = 'rotateZ(180deg)';
      break;
    case 'right':
      transform = 'rotateZ(-90deg)';
      break;
    case 'left':
      transform = 'rotateZ(90deg)';
      break;
  }
  return (
    <svg
      width={width ? width : height}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: transform }}
      className={className}
    >
      <path
        d='M10 13.75L3.75 7.5L4.625 6.625L10 12L15.375 6.625L16.25 7.5L10 13.75Z'
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};
export default ChevronIcon;
