import { useMutation, useQuery } from 'react-query';
import { useRepository } from '@context';

export const MONOLITH_KEY = 'monolith';

export const useGetSsoAllowedDomains = function () {
  const { monolithRepository } = useRepository();

  return useQuery({
    queryFn: () => monolithRepository.getSsoAllowedDomains(),
    queryKey: [`${MONOLITH_KEY}-domains`],
    select: (data): string[] => Object.keys(data).reduce((ret, key) => [...ret, ...data[key]], [] as string[]),
  });
};

export const usePostLogin = function () {
  const { monolithRepository } = useRepository();

  return useMutation({
    mutationKey: [`${MONOLITH_KEY}-login`],
    mutationFn: (data: { email: string; password: string }) => monolithRepository.postLogin(data.email, data.password),
  });
};

export const usePostVerifyB2cUser = function () {
  const { monolithRepository } = useRepository();

  return useMutation({
    mutationKey: [`${MONOLITH_KEY}-b2c-verify`],
    mutationFn: (email: string) => monolithRepository.postVerifyB2cUser(email),
  });
};
