import React, { ChangeEvent, FC, useEffect } from 'react';
import { useField } from 'informed';
import { InformedFieldState } from '@common/interfaces';
import css from './styles.module.scss';

export interface ITextField {
  disabled?: boolean;
  initialValue?: string;
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
}

const TextField: FC<ITextField> = ({ disabled, name, placeholder, initialValue, type = 'text', onChange }) => {
  const { fieldApi, fieldState, ref, render } = useField({ name });

  const { value, error, showError } = fieldState as InformedFieldState<string>;

  const { setValue, setTouched } = fieldApi;

  useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, []);

  return render(
    <div className={css.field}>
      <input
        type={type}
        ref={ref}
        disabled={disabled}
        placeholder={placeholder}
        className={`${css.input} ${showError ? css.inputError : ''}`}
        value={!value ? '' : value}
        onChange={(e) => {
          setValue(e.target.value, e);
          onChange?.(e);
        }}
        onBlur={(e) => setTouched(true, e)}
      />
      {showError ? <div className={css.error}>{error}</div> : null}
    </div>
  );
};

export default TextField;
