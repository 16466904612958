import React, { FC } from 'react';
import loaderImage from '../../assets/img/loading.gif';
import css from './styles.module.scss';

interface ILoader {
  loading?: boolean;
  full?: boolean;
}

const Loader: FC<ILoader> = ({ loading = false, full = false, children }) => {
  return (
    <div className={css.wrapper}>
      {loading ? (
        <div className={`${css.loaderContainer} ${full ? css.full : ''}`}>
          <div className={css.loader}>
            <img src={loaderImage} alt='loading' title='loading' />
          </div>
        </div>
      ) : null}
      <div className={`${css.container} ${loading ? css.disabled : ''}`}>{children}</div>
    </div>
  );
};

export default Loader;
