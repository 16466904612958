/* eslint-disable default-case */
import React from 'react';

interface IArrowIcon {
  type: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
}

const ArrowIcon = ({ type, className }: IArrowIcon): JSX.Element => {
  let transform;

  switch (type) {
    case 'top':
      transform = 'rotateZ(-90deg)';
      break;
    case 'bottom':
      transform = 'rotateZ(90deg)';
      break;
    case 'right':
      transform = 'none';
      break;
    case 'left':
      transform = 'rotateZ(180deg)';
      break;
  }

  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 256 256'
      width='20px'
      height='20px'
      style={{ transform }}
      className={className}
    >
      <g>
        <polygon points='79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128 ' />
      </g>
    </svg>
  );
};

export default ArrowIcon;
