import React, { FC, ReactElement, ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { PlacesType } from 'react-tooltip';
import { Nullable } from '@common/interfaces';
import css from './styles.module.scss';

interface ITip {
  text?: string;
  isVisible?: boolean;
  className?: string;
  content?: Nullable<ReactElement>;
  children?: ReactNode;
  place?: PlacesType;
}

const Tip: FC<ITip> = ({ children, text = '', isVisible = true, className, content, place }) => {
  return (
    <span
      className={`${css.tooltip} ${className}`}
      data-tooltip-place={place}
      data-tooltip-id='global-tooltip'
      data-tooltip-content={isVisible ? text : ''}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data-tooltip-html={content ? renderToStaticMarkup(content as any) : undefined}
    >
      {children}
    </span>
  );
};

export default Tip;
