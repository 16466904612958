import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { IPfiRepository, PfiLineItem, ProFormaInvoice } from '@common/interfaces';

class PfiRepository extends AxiosRepository implements IPfiRepository {
  constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  async createPfi(facilityId: string, bookingId: string) {
    const { data } = await this.post(`/facilities/${facilityId}/pfis/`, { booking: bookingId });
    return data;
  }

  async getPfi(facilityId: string, pfiId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/pfis/${pfiId}/`);
    return data;
  }

  async updatePfi(facilityId: string, pfiId: string, pfi: Partial<ProFormaInvoice>) {
    const { data } = await this.patch(`/facilities/${facilityId}/pfis/${pfiId}/`, pfi);
    return data;
  }

  async generatePfiPdf(facilityId: string, pfiId: string) {
    const { data } = await this.post(`/facilities/${facilityId}/pfis/${pfiId}/generate/`);
    return data;
  }

  async addPfiDocument(facilityId: string, pfiId: string, payload: FormData) {
    const { data } = await this.post(`/facilities/${facilityId}/pfis/${pfiId}/upload/`, payload);
    return data;
  }

  async addPfiLineItem(facilityId: string, pfiId: string, item: Partial<PfiLineItem>) {
    const { data } = await this.post(`/facilities/${facilityId}/pfis/${pfiId}/line-items/`, item);
    return data;
  }

  async updatePfiLineItem(facilityId: string, pfiId: string, item: Partial<PfiLineItem>) {
    const { data } = await this.patch(`/facilities/${facilityId}/pfis/${pfiId}/line-items/${item.uuid}/`, item);
    return data;
  }

  async deletePfiLineItem(facilityId: string, pfiId: string, itemId: string) {
    const { data } = await this.delete(`/facilities/${facilityId}/pfis/${pfiId}/line-items/${itemId}/`);
    return data;
  }
}

export default PfiRepository;
